import React, { useEffect, useContext } from "react";
import "./styles.css"

import PropTypes from "prop-types"
import IoMdCheckmark from "react-icons/lib/io/checkmark";

import useApiQuery, { Table } from "hooks/useQuery/api-query";
import { MatchContext } from "./MatchContext";

const FirstTimeLoginTable = ({ searchFilters, ticketNumberId }) => {

    const initialQuery = {
        sort: {},
        filters: {
            first_name: searchFilters.firstName !== "" ? [searchFilters.firstName] : [],
        last_name: searchFilters.lastName !== "" ? [searchFilters.lastName] : [],
        phone_number: searchFilters.phoneNumber !== "" ? [searchFilters.phoneNumber] : [],
        ticket_number: ticketNumberId !== "" ? [ticketNumberId] : [],
        email: searchFilters.email !== "" ? [searchFilters.email] : [],
        date_of_birth: searchFilters.dateOfBirth !== "" ? [searchFilters.dateOfBirth] : []
        },
        pagination: { page: 1, per: 150 }
      };
      
    const { query, rows, queryDispatcher, data, isFetching, fetchData } =
        useApiQuery(`first-time-registration-dashboard/${ticketNumberId}/matching-patients`, initialQuery, "Patients");

    useEffect(() => {
        const { firstName, lastName, phoneNumber, email, dateOfBirth } = searchFilters;

        let queryUpdated = {
            filters: {
                first_name: firstName ? [firstName] : [],
                last_name: lastName ? [lastName] : [],
                phone_number: phoneNumber ? [phoneNumber] : [],
                ticket_number: ticketNumberId ? [ticketNumberId] : [],
                email: email ? [email] : [],
                date_of_birth: dateOfBirth ? [dateOfBirth] : []
            },
          pagination: { page: 1, per: 150 }
        }

        fetchData(queryUpdated)
    }, [searchFilters])

    return (
        <div style={{ marginTop: "2em" }}>
            <h2 className="section-title">Possible Matches</h2>
            <div>
                <Table
                    query={query}
                    data={data}
                    queryDispatcher={queryDispatcher}
                    isFetching={isFetching}
                    containerClass="query-table patients-table dynamic-columns"
                    columns={[
                        {
                            header: "Status",
                            field: "Status",
                        },
                        {
                            header: "Name",
                            field: "Name",
                        },
                        {
                            header: "Account Number",
                            field: "AccountNumber",
                        },
                        {
                            header: "Email",
                            field: "Email",
                        },
                        {
                            header: "Phone Number",
                            field: "PhoneNumber",
                        },
                        {
                            header: "Date of Birth",
                            field: "DateOfBirth",
                        },
                        {
                            header: "Company",
                            field: "Company",
                        },
                        {
                            header: "Stage",
                            field: "Stage",
                        },
                    ]}
                    rows={rows}
                    rowKey="id"
                    perPageOptions={[
                        { text: "50", value: 50 },
                        { text: "150", value: 150 },
                        { text: "500", value: 500 }
                    ]}
                    TableRow={TableRow}
                />
            </div>


        </div>
    )
}

export default FirstTimeLoginTable


FirstTimeLoginTable.propTypes = {
    searchFilters: PropTypes.shape({
        email: PropTypes.string,
        phoneNumber: PropTypes.string,
        dateOfBirth: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        ticketNumberId: PropTypes.string
    }),
    ticketNumberId: PropTypes.string

};

const TableRow = ({ PatientId, Status, Name, AccountNumber, Email, PhoneNumber, DateOfBirth, Company, Stage, FirstNameMatch, LastNameMatch, AccountNumberMatch, EmailMatch, PhoneNumberMatch, DateOfBirthMatch}) => {
    const { selectPatient } = useContext(MatchContext);
    return (
        <tr>
            <td>{Status}</td>
                <td style={{cursor: "pointer", color: "#4281A6"}} title="View this patient's profile" onClick={() => selectPatient(PatientId)}>{FirstNameMatch && LastNameMatch && <IoMdCheckmark />} {Name}</td>
            <td>{AccountNumberMatch && <IoMdCheckmark />} {AccountNumber} </td>
            <td>{EmailMatch && <IoMdCheckmark />} {Email}  </td>
            <td>{PhoneNumberMatch && <IoMdCheckmark />} {PhoneNumber}</td>
            <td>{DateOfBirthMatch && <IoMdCheckmark />} {DateOfBirth}</td>
            <td>{Company}</td>
            <td>{Stage}</td>
        </tr>
    )
}
TableRow.propTypes = {
    PatientId: PropTypes.string,
    Status: PropTypes.string,
    Name: PropTypes.string,
    AccountNumber: PropTypes.string,
    Email: PropTypes.string,
    PhoneNumber: PropTypes.string,
    DateOfBirth: PropTypes.string,      
    Company: PropTypes.string,
    Stage: PropTypes.string,
    FirstNameMatch: PropTypes.bool,
    LastNameMatch: PropTypes.bool,
    AccountNumberMatch: PropTypes.bool,
    EmailMatch: PropTypes.bool,
    PhoneNumberMatch: PropTypes.bool,
    DateOfBirthMatch: PropTypes.bool
};
