import React, { Component } from "react";
import PropTypes from "prop-types";
import { getUsers, isUsersFetching } from "reducers";
import {
  USER_FETCH_ACTION_CREATORS,
  COMPANY_ADMINISTRATOR,  
  exportUserTable
} from 'actions/users'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import DataColumnFilterTable from 'components/ui/data-column-filter-table'
import { PageHeader, InlineWaiting } from 'components/ui'
import capitalize from 'lodash/capitalize'
import moment from 'moment'
import { formatPhone } from "utils/misc";

const [fetchUsers, invalidateUsers] = USER_FETCH_ACTION_CREATORS[COMPANY_ADMINISTRATOR]
class MultiFilterCompanyAdministratorTable extends Component {
  constructor(props) {
    super(props)
    this.state={
        filteredRows: []
    }
  }
  
  componentDidMount() {
    this.handleRefresh();
  }
  
  handleRefresh = () => {
    const { dispatch } = this.props
    dispatch(invalidateUsers())
    dispatch(fetchUsers())
  }

  handleExport = (filteredRows) => {
    this.props.dispatch(exportUserTable('company_administrators', filteredRows))
  }
  
  render() {
    const {
      users,      
      isFetching
    } = this.props
    const nameSort = ({name}) => capitalize(name)

    return (
      <div>
        <PageHeader
        title= "Company Administrators"
        />
        <DataColumnFilterTable
          columns={[
            {
              title: 'Sort by name',
              header: 'Name',
              field: user => user.name,
              format: user => (
                <Link to={`/users/${user.id}`}>                
                  {user.name}
                </Link>
              ),
            
              filterTest: 'name'
            },                       
            {
              title: 'Sort by Email',
              header: 'Email',
              field: user => user.email,
              
            },
            {
              title: 'Sort By Phone Number',
              header: 'Phone Number',
              field: user => formatPhone(user.phone_number),
              
            },
            {
                title: 'Sort by Last Signed In',
                header: 'Last Signed In',
                field: user => user.last_signed_in === null ?  "No Record" : moment(user.last_signed_in).format('MMMM Do YYYY, h:mm:ss a')
            },
            {
                title: 'Sort by Company',
                header: 'Company',
                field: user => user.company.value,
                format: user => (
                  <Link to={`/companies/${user.company.key}/profile`}>                
                    {user.company.value}
                  </Link>)
            },
            { 
              title: 'Sort by Active',
              header: 'Active',
              field: user => user.active.toString(),
              boolean: true
            }           
          ]}
          
          rows={users}
          onRefresh = {this.handleRefresh}
          onExport= {this.handleExport}
          initialSort={{ iteratee: nameSort }}
          initialFilters={[{
            input: '',
            columnIndex: 5,
            testOpKey: 'REG_EXP',
            selectActiveBoolean: 'true'
          }]} />
        {isFetching && <InlineWaiting reason='Fetching Company Administrators...' />}
      </div>
    )
  }
}

MultiFilterCompanyAdministratorTable.propTypes = {
  dispatch: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  isFetching: PropTypes.bool
};

export default connect(state => ({
  users: getUsers(state, COMPANY_ADMINISTRATOR),
  isFetching: isUsersFetching(state, COMPANY_ADMINISTRATOR)
}))(MultiFilterCompanyAdministratorTable)
