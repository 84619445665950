import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import CoachLoginsTable from "./coach-logins-table";
import MdArrowDropdown from "react-icons/lib/md/arrow-drop-down";
import MdArrowUp from "react-icons/lib/md/arrow-drop-up";
import { useFetch } from "hooks";
import { put } from "utils/api";
import { path } from "ramda";
import { errorMessage } from "actions/message";

const CoachLogins = ({ minimizeAll, requestedDates, selectedTeam }) => {
  const [isMinimized, setMinimized] = useState(false);
  const [ history, setHistory] = useState([])
  useEffect(() => {
    setMinimized(minimizeAll);
  }, [minimizeAll, setMinimized]);
  const {
    response: loginHistory,
    fetchData: getLoginHistory,
    // isFetching
  } = useFetch({
    defaultValue: [],
    apiFn: payload => put("employee_login_history", payload),
    transformError: path(["response", "body", "status"]),
    onError: error => {
      errorMessage(`Failed to pull breakdown: ${error.message}`);
    }
  });

  const getHistoryOfLogins = () => {
    const payload = {
      GUID: selectedTeam,
      startDate: requestedDates.start_date,
      endDate: requestedDates.end_date
    };
    getLoginHistory(payload);
  };

  useEffect(() => {
    setHistory(loginHistory);
  }, [loginHistory]);

  useEffect(() =>{
    getHistoryOfLogins();
  }, [selectedTeam, requestedDates])

  return (
    <div className="block open-orders">
      <div className="block-header">
        <span onClick={() =>{
           setMinimized(!isMinimized)}}>
          Logins
          <i className="flat-icon-button">
            {isMinimized ? <MdArrowDropdown /> : <MdArrowUp />}
          </i>
        </span>
      </div>
      {!isMinimized && (
        <div className="block-content">
          <CoachLoginsTable data={history} dates={requestedDates}/>
        </div>
      )}
    </div>
  );
};

CoachLogins.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  minimizeAll: PropTypes.bool,
  requestedDates: PropTypes.shape({
    start_date: PropTypes.string,
    end_date: PropTypes.string
  }),
  selectedTeam: PropTypes.string
};

export default connect(state => ({
  data: selectors.getTeamCoachOpenOrders(state)
}))(CoachLogins);
