import React, { useState, useEffect, useContext } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { selectors } from "reducers";
import MdArrowDropdown from "react-icons/lib/md/arrow-drop-down";
import MdArrowUp from "react-icons/lib/md/arrow-drop-up";
import useSignalr from "features/signalR/use-signalr";
import {
  getOnlineUsers,
  joinGroup,
  leaveGroup
} from "features/signalR/actions";
import CoachStatusRow from "./coach-status-row";
import MdWarning from "react-icons/lib/md/warning";
import useLocalQuery, { Table, NonSortableHeader } from "hooks/useQuery/local-query";
import moment from "moment";
import TeamDashboardContext from "../context";

const CoachStatus = ({ minimizeAll, coachOnlineTimes, connectedToSignalR }) => {
  const { rows, query, queryDispatcher } = useLocalQuery(coachOnlineTimes, {
    sort: { by: "coach_name", direction: "desc" }
  });
  const [isMinimized, setMinimized] = useState(false);
  useSignalr(
    [joinGroup("ClientStatusListener"), getOnlineUsers()],
    [leaveGroup("ClientStatusListener")]
  );
  useEffect(() => {
    setMinimized(minimizeAll);
  }, [minimizeAll, setMinimized]);
  const {
    requestedDates: { end_date }
  } = useContext(TeamDashboardContext);
  const appendCurrentValues =
    end_date && !moment(end_date, "MM/DD/YYYY").isBefore(moment(), "day");
  return (
    <div className="block coach-status">
      <div
        className="block-header"
        style={{ cursor: "pointer" }}
        onClick={() => setMinimized(!isMinimized)}
      >
        Coaches
        <span>
          {!connectedToSignalR && (
            <span
              style={{ padding: "0 10px" }}
              title="Not connected to online reporting service"
            >
              <MdWarning color="rgb(232, 145, 12)" size={18} />
            </span>
          )}
          <i style={{ padding: "0 2px" }}>
            {isMinimized ? <MdArrowDropdown /> : <MdArrowUp />}
          </i>
        </span>
      </div>
      {appendCurrentValues && <WatchCoachTimeUpdates />}
      {!isMinimized && (
        <div className="block-content">
          <Table
            containerClass="query-table coach-status-table"
            rows={rows}
            query={query}
            queryDispatcher={queryDispatcher}
            TableRow={CoachStatusRow}
            rowKey="coach_guid"
            columns={[
              {
                header: "",
                field: "coach_settings",
                style: { width: 20 }
              },
              {
                header: "Name",
                field: "coach_name",
                style: { width: 250 }
              },
              {
                header: "Online",
                field: "connected_at",
                style: { width: 50 },
                Cell: () => <NonSortableHeader text="Online" />
              },
              {
                header: "Call Duration",
                field: "callConnectedAt",
                style: { width: 50 },
                Cell: () => <NonSortableHeader text="Call Duration" />
              },
              {
                header: "Autodialer Session",
                field: "autodialerOpenedAt",
                Cell: () => <NonSortableHeader text="Autodialer Session" />
              },
              {
                header: "Total Time Online",
                field: "total_time_in_seconds"
              },
              {
                header: "Total Time In Autodialer",
                field: "time_in_queue_seconds"
              },
              {
                header: "Autodialer Usage %",
                field: "autodialer_usage", // does not exist but used as key
                Cell: () => <NonSortableHeader text="Autodialer Usage %" />
              }
            ]}
          />
        </div>
      )}
    </div>
  );
};

const WatchCoachTimeUpdates = () => {
  useSignalr(
    [joinGroup("ClientTimeUpdates")],
    [leaveGroup("ClientTimeUpdates")]
  );
  return null;
};

CoachStatus.propTypes = {
  coachOnlineTimes: PropTypes.arrayOf(PropTypes.object).isRequired,
  minimizeAll: PropTypes.bool,
  connectedToSignalR: PropTypes.bool
};

export default connect(state => ({
  coachOnlineTimes: selectors.getTeamCoachOnlineTotals(state),
  connectedToSignalR: selectors.getIsConnectedSignalr(state)
}))(CoachStatus);
