import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { browserHistory } from "browser-history";
import compose from "lodash/fp/compose";
import moment from "moment";
import {
  withQuery,
  withTableHandlers,
  withApi,
  withExport,
  withHeaderFooter
} from "features/query";
import withBatch from "features/batch";
import { requestFulfillmentStatusUpdate } from "actions/fulfillment-status";
import { setOrderBatchIds } from "actions/batch";
import { createTab } from "actions/system-tray";
import { selectors } from "reducers";
import Table from "components/ui/impl/table";
import InvoiceReport from "components/order-status/invoice-report";
import IoMdClipboard from "react-icons/lib/io/clipboard";

import AssignmentFilter from "components/screens/dashboard/sleep-coach-dashboard/patients-table/sleepcoach-assignemtn-filter";

const dateFormat = key => record => {
  const value = record[key];
  return value ? moment(value).format("YYYY / MM / DD") : "";
};

class FulfillmentStatusTable extends Component {
  constructor(props) {
    super(props);
    this.state = { status: "" };
  }

  componentDidUpdate({ isUpdatingFulfillment: wasRequestingUpdate }) {
    const { isUpdatingFulfillment, requestData } = this.props;
    if (wasRequestingUpdate && !isUpdatingFulfillment) {
      requestData();
    }
  }

  handleSelectChange = ({ target: { value } }) =>
    this.setState({ status: value });

  handleUpdateSelected = () => {
    const { markedForBatch, batchClear, dispatch, requestData } = this.props;
    const { status } = this.state;
    if (markedForBatch.length && status) {
      dispatch(requestFulfillmentStatusUpdate(markedForBatch, status));
      batchClear();
      requestData();
      this.setState({ status: "" });
    }
  };

  render() {
    return (
      <div className="fulfillment-status-table">
        <Table {...this.props} />
        <div className="update-status-container">
          <select value={this.state.status} onChange={this.handleSelectChange}>
            <option value="" />
            {!(this.props.fulfillmentStatus == "ready-to-ship") && (
              <option value="pending fulfillment">
                Ready to ship (not sent to fulfillment center)
              </option>
            )}
            {!(this.props.fulfillmentStatus == "pending-shipment") && (
              <option value="pending shipment">
                Pending shipment (sent to fulfillment center and waiting for
                shipment confirmation)
              </option>
            )}
            {!(this.props.fulfillmentStatus == "shipped-and-completed") && (
              <option value="completed">Move to completed</option>
            )}
          </select>
          <button
            className="button postfix"
            disabled={!this.state.status || !this.props.markedForBatch.length}
            onClick={this.handleUpdateSelected}
          >
            Update Selected
          </button>
        </div>
      </div>
    );
  }
}

export default compose(
  withQuery(),
  withApi({
    api: {
      route: "fulfillment",
      responseKey: "fulfillment",
      idKey: "id",
      entityKey: "fulfillmentRows"
    }
  }),
  withExport(),
  withBatch({ recordIdKey: "id" }),
  connect(
    (state, { batchColumn, markedForBatch, query, queryId, dispatch }) => {
      const companies = selectors.getFormOptionsCompany(state);
      const sleepCoaches =
        selectors.getFormOptionsSleepCoachWithUserFirst(state);
      const fulfillmentTypes = selectors.getEnumFulfillmentTypes(state);
      return {
        isUpdatingFulfillment: selectors.getIsUpdatingFulfilment(state),
        columns: [
          /* eslint-disable react/prop-types */
          batchColumn,
          {
            header: "Last Updated",
            field: "updated_at",
            format: dateFormat("updated_at")
          },
          {
            header: "Order Created",
            field: "created_at",
            format: dateFormat("created_at")
          },
          {
            header: "Invoice Number",
            field: "invoice_number",
            format: ({ id, invoice_number }) => (
              <Link to={`/orders/${id}`}>{invoice_number}</Link>
            )
          },
          {
            header: "Company",
            field: "company",
            filters: companies,
            filterDownSelect: true
          },
          {
            header: "Patient",
            field: "patient",
            format: ({ patient_id, patient }) => (
              <Link to={`/patients/${patient_id}`}>{patient}</Link>
            )
          },
          {
            header: "Sleep Coach",
            field: "sleep_coach",
            filters: sleepCoaches,
            filterDownSelect: true
          },
          {
            header: "Assignment",
            field: "sleep_coach_assignment",
            renderFilter: props => <AssignmentFilter {...props} />
          },
          {
            header: "Team",
            field: "team_name"
          },
          {
            header: "Fulfillment Type",
            field: "fulfillment_type",
            format: ({ fulfillment_type }) => fulfillment_type || "INTERNAL",
            filters: fulfillmentTypes
          },
          {
            header: "Status Changed",
            field: "statusChangedDate",
            format: dateFormat("statusChangedDate")
          }
        ],
        customDisplay: [
          () =>
            markedForBatch.length > 0 ? (
              <span style={{ color: "white" }} key="marked-for-batch-label">
                {markedForBatch.length} Orders Selected
              </span>
            ) : null
        ],
        customButtons: [
          {
            component: () => (
              <InvoiceReport
                query={query}
                queryId={queryId}
                key="fulfillment-invoice-button"
              />
            )
          },
          {
            icon: () => <IoMdClipboard />,
            title: `Process orders (${markedForBatch.length} selected)`,
            onClick: () => {
              if (markedForBatch.length) {
                dispatch(setOrderBatchIds(markedForBatch));
                dispatch(
                  createTab({
                    type: "orders",
                    key: "orders",
                    label: `Orders (${markedForBatch.length})`,
                    meta: { ids: markedForBatch }
                  })
                );
              }
            }
          }
        ]
      };
    },
    (_, { markedForBatch }) => ({
      requestPrint: () => {
        browserHistory.push({
          pathname: `/fulfillment/print-packing-slips`,
          state: { orderIds: markedForBatch }
        });
      }
    })
  ),
  withHeaderFooter({
    perPageOptions: [150, 300, 500],
    forceBatchReset: true
  }),
  withTableHandlers()
)(FulfillmentStatusTable);

FulfillmentStatusTable.propTypes = {
  batchClear: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  fulfillmentStatus: PropTypes.string,
  isUpdatingFulfillment: PropTypes.bool,
  markedForBatch: PropTypes.arrayOf(PropTypes.string).isRequired,
  requestData: PropTypes.func.isRequired
};
