import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { sendPatientEmailFromTemplate } from "actions/patient";
import { selectors } from "reducers";
import { Formik, Form, Field } from "formik";
import { formatPhone } from "utils/misc";
import { isValidPhoneNumber } from "utils/form-validation";
import { InlineWaiting } from "components/ui";
import { prop } from "ramda";

const PreviewEmail = ({
  closeSelf,
  patientId,
  template,
  template_id,
  patientInfo,
  userInfo,
  sendPatientEmailFromTemplate
}) => (
  <Formik
    onSubmit={async values => {
      await sendPatientEmailFromTemplate(patientId, template_id, values);
      closeSelf();
    }}
    initialValues={patientInfo.speaks_spanish
      ?  getInitialValues(template.spanish_body, patientInfo)
      :  getInitialValues(template.body, patientInfo) }
  >
    {({ isSubmitting }) => (
      <Form className="email-preview-container">
        <div className="email-subject">
          <span className="email-subject-label">Subject: </span>{" "}
          {patientInfo.speaks_spanish
      ?  getMatches(userInfo, template.spanish_subject)
      :  getMatches(userInfo, template.subject)}
        </div>
        <p className="email-preview-body">
        {patientInfo.speaks_spanish
      ?  getMatches(userInfo, template.spanish_body, patientInfo)
      :  getMatches(userInfo, template.body, patientInfo)}
        </p>
        <div className="email-preview-actions">
          <button onClick={closeSelf} className="warning" type="button">
            Cancel
          </button>
          {isSubmitting ? (
            <InlineWaiting />
          ) : (
            <button type="submit">Send</button>
          )}
        </div>
      </Form>
    )}
  </Formik>
);

PreviewEmail.propTypes = {
  userInfo: PropTypes.object.isRequired,
  patientInfo: PropTypes.object.isRequired,
  patientId: PropTypes.string.isRequired,
  closeSelf: PropTypes.func.isRequired,
  template_id: PropTypes.string.isRequired,
  template: PropTypes.shape({
    id: PropTypes.string.isRequired,
    template_name: PropTypes.string,
    subject: PropTypes.string,
    body: PropTypes.string,
    spanish_subject: PropTypes.string,
    spanish_body: PropTypes.string
  }).isRequired,
  sendPatientEmailFromTemplate: PropTypes.func.isRequired
};

export default connect(
  (state, { patientId, template_id }) => ({
    template: selectors.getPatientEmailTemplate(state, template_id),
    patientInfo: selectors.getPatientInfo(state, patientId),
    userInfo: selectors.getUser(state)
  }),
  {
    sendPatientEmailFromTemplate
  }
)(PreviewEmail);

const getInitialValues = (text, patientInfo) => {
  const pattern = /\{.+?\}/g;
  let matches = text.match(pattern) || [];
  matches = matches.map(v => v.replace(/\{|\}/g, ""));
  const inputs = matches.filter(v => !!prop(v, patientInfo));
  const outputs = matches.filter(v => !prop(v, patientInfo));
  return {
    ...inputs.reduce(
      (acc, curr) => ({
        ...acc,
        [curr]: isValidPhoneNumber(prop(curr, patientInfo))
          ? formatPhone(prop(curr, patientInfo))
          : prop(curr, patientInfo)
      }),
      {}
    ),
    ...outputs.reduce((acc, curr) => ({ ...acc, [curr]: "" }), {})
  };
};

const getMatches = (userInfo, text, patientInfo) => {
  const pattern = /\{.+?\}/g;
  const splitText = text.split(pattern);
  if (splitText.length <= 1) {
    return text;
  }
  patientInfo.current_user_full_name = `${userInfo.first_name} ${userInfo.last_name}`;
  const matches = text.match(pattern).map(v => v.replace(/\{|\}/g, ""));
  const stripHtml = elem => elem.replace(/<[^>]*>/g, "");
  return splitText.reduce(
    (arr, element, index) =>
      matches[index]
        ? [
            ...arr,
            stripHtml(element),
            <RenderMatch
              key={index}
              valueName={matches[index]}
              patientInfo={patientInfo}
            />
          ]
        : [...arr, stripHtml(element)],
    []
  );
};

const RenderMatch = ({ valueName, patientInfo }) => {
  const outputValue = prop(valueName, patientInfo);
  if (outputValue)
    return isValidPhoneNumber(outputValue) ? (
      <b>{formatPhone(outputValue)}</b>
    ) : (
      <b>{outputValue}</b>
    );
  else return <Field name={valueName} type={getDataType(valueName)} required />;
};

RenderMatch.propTypes = {
  valueName: PropTypes.string.isRequired,
  patientInfo: PropTypes.object.isRequired
};

const getDataType = value => {
  switch (value.slice(-2)) {
    case "#f":
    case "#d":
      return "number";
    default:
      return "text";
  }
};
