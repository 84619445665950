import React from "react";
import PropTypes from "prop-types";
import { RowSelectCell } from "hooks/useRowSelectNew";
import { MdHeadset } from "react-icons/lib/md";
import { IoPerson, IoHammer } from "react-icons/lib/io";
import { formatPhone } from "utils/misc";
import { formatDate } from "utils/dates";
import { Link } from "react-router-dom";
import VmailRecording from "components/screens/vmail-table/vmail-recording";

const VoicemailTableRow = row => {
  return (
    <tr>
      <RowSelectCell id={row.id} />
      <td>
        <VmailRecording id={row.id} />
      </td>
      <td>
        <span
          style={{ paddingRight: 5, display: row.listened_to ? "" : "none" }}
          title={
            "Listened By " +
            row.listened_by.first_name +
            " " +
            row.listened_by.last_name
          }
        >
          <MdHeadset size={"14"} color={"green"} />
        </span>
        <span
          style={{ display: row.assigned ? "" : "none" }}
          title={`Assigned To ${row.sleepCoachAssigned.first_name} ${row.sleepCoachAssigned.last_name}`}
        >
          <IoPerson size={"14"} color={"green"} />
        </span>
        <span
          style={{ display: row.worked ? "" : "none" }}
          title={`Worked By ${row.worked_by.first_name} ${row.worked_by.last_name}`}
        >
          <IoHammer size={"14"} color={"green"} />
        </span>
      </td>
      <td>
        <Link
          title={"Click Here To Go To Voicemail Profile"}
          to={`/voicemails/${row.id}`}
        >
          {row.vmID}
        </Link>
      </td>
      <td>{formatDate(row.created_at, 'MM/DD/YYYY - h:mm:ss a')}</td>
      <td>{row.calledCompanyTeam}</td>
      <td>
        {/[a-bA-Z]/.test(row.to_number) == true ? (
          <span title={formatPhone(row.to_number)}>
            {formatPhone(row.to_number)}
          </span>
        ) : row.calledCompanyName ? (
          <span title={formatPhone(row.to_number)}>
            {" "}
            {row.calledCompanyName}
          </span>
        ) : (
          <span title={formatPhone(row.to_number)}>
            {formatPhone(row.to_number)}
          </span>
        )}
      </td>
      <td>{row.from_number && formatPhone(row.from_number)}</td>
      <td>{row.sleepCoachAssigned?.name}</td>
      <td>{(row.assigned_at) ? formatDate(row.assigned_at, 'MM/DD/YYYY - h:mm:ss a'): ''}</td>
      <td>{row.worked_by?.name}</td>
    </tr>
  );
};

VoicemailTableRow.propTypes = {
  vmID: PropTypes.number,
  to_number: PropTypes.string
};

export default VoicemailTableRow;
