import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose, withProps } from "recompose";
import {
  withQuery,
  withTableHandlers,
  withApi,
  withHeaderFooter
} from "features/query";
import withBatch from "features/batch";
import { selectors } from "reducers";
import Table from "components/ui/impl/table";
import moment from "moment";
import { outreachCycleOptions } from "utils/enum";
import TeamCoachFilter from "./team-sleepcoach-filter";
import TableControls from "./table-controls";
import CompanyBranchFilter from "components/order-status/company-branch-filter";

const getContactMethodSimpleNames = contact_methods =>
  contact_methods.map(
    method =>
      ({
        "Place Manual Call": "Call",
        "Send Resupply Email": "Resupply Email",
        "Send Resupply Push Notification": "Resupply Push",
        "Send Resupply SMS": "Resupply SMS",
        "Place IVR Call": "IVR",
        "Place Automatic Order": "Auto-Order",
        "Bad Phone Number": "None",
        "Send Form Pending Answer Reminder Email": " Forms Email",
        "Send Form Pending Answer Reminder Push Notification": "Forms Push",
        "Send Form Pending Answer Reminder SMS": "Forms SMS"
      }[method])
  );

const formatDate = ({ outreach_date }) =>
  moment(outreach_date).format("MM / DD / YYYY");

const formatEligBool = ({ over_elig_date }) => (over_elig_date ? "Yes" : "No");

const formatSetupBool = ({ new_setup }) => (new_setup ? "Yes" : "No");

const formatRemoteSetupBool = ({ remote_setup }) =>
  remote_setup ? "Yes" : "No";

const SleepcoachDashboardTable = compose(
  connect(state => ({
    sleepCoachOptions: selectors.getFormOptionsSleepCoachWithUserFirst(state),
    contactActionsOptions: selectors.getContactActionsOptions(state),
    formOfContactOptions: selectors.getFormOfContactOptions(state),
    companyOptions: selectors.getFormOptionsCompany(state),
    userId: selectors.getUserId(state),
    userTeam: selectors.getUserTeam(state),
    userRole: selectors.getUserRole(state),
    teamNameOptions: selectors.getTeamNameOptionsKeyAsValue(state),
    insuranceTypeNames: selectors
      .getFormOptionsInsurance(state)
      .map(({ text }) => ({ text, value: text }))
  })),
  withQuery({
    queryId: "sleepcoach-dashboard-table"
  }),
  withApi({
    apiId: "sleepcoach-dashboard-table",
    api: {
      route: "patients/needs_contact",
      responseKey: "patients",
      idKey: "id",
      entityKey: "dashboardRows"
    }
  }),
  withBatch({ recordIdKey: "id" }),

  withProps(
    ({
      markedForBatch,
      markForBatch,
      batchColumn,
      companyOptions,
      contactActionsOptions,
      formOfContactOptions,
      requestData,
      batchClear,
      insuranceTypeNames,
      teamNameOptions,
      userRole
    }) => ({
      columns: [
        batchColumn,
        {
          header: "Name",
          field: "last_name",
          format: (
            { first_name, last_name, id } //eslint-disable-line
          ) => (
            <Link to={`/patients/${id}`}>
              {first_name} {last_name}
            </Link>
          )
        },
        ["SleepCoach", "Administrator"].includes(userRole)
          ? {
              header: "Company",
              field: "company",
              filters: companyOptions,
              filterKey: "company_id",
              filterDownSelect: true
            }
          : {
              header: "Company",
              field: "company",
              renderFilter: props => <CompanyBranchFilter {...props} />
            },
        {
          header: "Branch",
          field: "company_branch_name",
          filterKey: "company_branch_guid",
          filterDownSelect: true
          // style: { width: 150 }
        },
        {
          header: "Sleep Coach",
          field: "sleep_coach",
          renderFilter: props => (
            <TeamCoachFilter {...props} teamNameOptions={teamNameOptions} />
          ),
          filterKey: "sleep_coach",
          filterDownSelect: true
          // style: { width: 150 }
        },
        {
          header: "Team",
          field: "team_name",
          filters: teamNameOptions,
          filterKey: "team_id"
        },
        {
          header: "Form of Contact",
          field: "form_of_contact",
          filters: formOfContactOptions
        },
        {
          header: "Contact Actions",
          field: "contact_methods",
          format: (
            { contact_methods } //eslint-disable-line
          ) =>
            `[${getContactMethodSimpleNames(contact_methods).map(
              (method, idx) => {
                return ` ${method}${
                  idx + 1 !== contact_methods.length ? "," : ""
                } `;
              }
            )}]`,
          filters: contactActionsOptions
        },
        {
          header: "Cycle",
          field: "patient_outreach_cycle_count",
          filters: outreachCycleOptions
        },

        {
          header: "New Setup",
          format: formatSetupBool,
          field: "new_setup",
          filters: [
            {
              text: "Yes",
              value: true
            },
            {
              text: "No",
              value: false
            }
          ]
        },
        {
          header: "Remote Setup",
          format: formatRemoteSetupBool,
          field: "remote_setup",
          filters: [
            {
              text: "Yes",
              value: true
            },
            {
              text: "No",
              value: false
            }
          ]
        },
        {
          header: "Override Date",
          field: "over_elig_date",
          format: formatEligBool,
          filters: [
            {
              text: "Yes",
              value: true
            },
            {
              text: "No",
              value: false
            }
          ]
        },
        {
          header: "Insurance",
          field: "insurance_type",
          filters: insuranceTypeNames
        },
        {
          header: "Outreach Date",
          format: formatDate,
          field: "outreach_date"
        }
      ],
      customDisplay: [
        () =>
          markedForBatch.length > 0 ? (
            <span style={{ color: "white" }}>
              {markedForBatch.length} Patients Selected
            </span>
          ) : null
      ],
      customButtons: [
        {
          component: () => (
            <TableControls
              selectedPatients={markedForBatch}
              setSelectedPatients={markForBatch}
              setPatientBatchIds={markForBatch}
              handleRefresh={requestData}
              batchClear={batchClear}
            />
          )
        }
      ]
    })
  ),
  withHeaderFooter({
    perPageOptions: [500, 1000, 3000],
    forceBatchReset: true,
    perPageLimit: 10000,
    scrollable: true,
    showCount: true
  }),
  withTableHandlers()
)(Table);

export default SleepcoachDashboardTable;
