import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import useSignalr from "features/signalR/use-signalr";
import {
  getOnlineUsers,
  joinGroup,
  leaveGroup
} from "features/signalR/actions";
import { fetchEmployeeTimes } from "actions/employee-online-times";
import useDateRange from "hooks/useDateRange";
import DateRange from "components/ui/date-range";
import { PageHeader } from "components/ui";
import { today } from "utils/dates";
import useLocalQuery, { Table, NonSortableHeader } from "hooks/useQuery/local-query";
import EmployeeTableRow from "./employee-table-row"

const EmployeeOnlineTimes = ({
  fetchEmployeeTimes,
  teamNameOptions,
  employees
}) => {
  useSignalr(
    [joinGroup("ClientStatusListener"), getOnlineUsers()],
    [leaveGroup("ClientStatusListener")]
  );
  const {
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    dateRangeIsValid
  } = useDateRange({
    startDate: today(),
    endDate: today()
  });
  const [isFetching, setIsFetching] = React.useState(false);
  const [requestedEndDate, setRequstedEndDate] = React.useState(endDate);
  const requestEmployeeTimes = async (startDate, endDate) => {
    try {
      setIsFetching(true);
      await fetchEmployeeTimes(startDate, endDate);
      setRequstedEndDate(endDate);
    } finally {
      setIsFetching(false);
    }
  };
  const { rows, query, queryDispatcher } = useLocalQuery(employees, {
    sort: { by: "call_group", direction: "desc" },
    filters: { isOnline: [true] }
  });
  React.useEffect(() => {
    requestEmployeeTimes(startDate, endDate);
  }, []);
  return (
    <div className="phone-dashboard-container">
      <PageHeader title="Employees">
        <div className="phone-dashboard-header">
          <DateRange
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            handleSubmit={(startDate, endDate) => {
              requestEmployeeTimes(startDate, endDate);
            }}
            isValid={dateRangeIsValid}
            isFetching={isFetching}
          />
        </div>
      </PageHeader>
      <Table
        containerClass="query-table coach-status-table"
        rows={rows}
        query={query}
        queryDispatcher={queryDispatcher}
        TableRow={u => (
          <EmployeeTableRow requestedEndDate={requestedEndDate} {...u} />
        )}
        rowKey="coach_guid"
        columns={[
          {
            header: "",
            field: "coach_settings",
            style: { width: 20 }
          },
          {
            header: "Name",
            field: "coach_name"
          },
          {
            header: "Team",
            field: "team_name",
            filters: teamNameOptions
          },
          {
            header: "Online",
            field: "isOnline",
            filters: [
              { value: true, text: "Yes" },
              { value: false, text: "No" }
            ]
          },
          {
            header: "Autodialer",
            field: "isInDialer",
            filters: [
              { value: true, text: "Yes" },
              { value: false, text: "No" }
            ]
          },
          {
            header: "Call Duration",
            field: "callConnectedAt",
            style: { width: 50 },
            Cell: () => <NonSortableHeader text="Call Duration" />
          },
          {
            header: "Total Time Online",
            field: "total_time_in_seconds"
          },
          {
            header: "Total Time In Autodialer",
            field: "time_in_queue_seconds"
          },
          {
            header: "Autodialer Usage %",
            field: "autodialer_usage", // does not exist but used as key
            Cell: () => <NonSortableHeader text="Autodialer Usage %" />
          }
        ]}
      />
    </div>
  );
};

EmployeeOnlineTimes.propTypes = {
  employees: PropTypes.arrayOf(PropTypes.object),
  fetchEmployeeTimes: PropTypes.func.isRequired,
  teamNameOptions: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default connect(
  state => ({
    employees: selectors.getSignalREmployeeTotalTimes(state),
    teamNameOptions: selectors.getTeamNameOptions(state)
  }),
  {
    fetchEmployeeTimes
  }
)(EmployeeOnlineTimes);
