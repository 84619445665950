import React, { useContext } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { PureModal, TableButton, Spinner } from "components/ui";
import { exportFirstTimeRegistration } from "actions/exports";
import { PatientsTableSettingsContext } from "./settings.jsx";
import HasRolePermission from "components/shared/has-role-permission";
import * as R from "ramda";
import BulkFirstTimeRegistrationAssignModal from "./bulk-first-time-registration-assign-modal";
import IoMdRefresh from "react-icons/lib/io/refresh";
import FaFileExcelO from "react-icons/lib/fa/file-excel-o";
import IoMdEdit from 'react-icons/lib/io/edit';
import { IoArrowReturnLeft } from "react-icons/lib/io/index.js";

const PatientsTableControls = ({
  refreshData,
  isFetching,
  selectedRows,
  clearSelectedRows,
  query,
  resetSelected,
  resetting
}) => {
  const dispatch = useDispatch();
  const { tableSettings } = useContext(PatientsTableSettingsContext);
    
  const reset = async () => {
    let ticketIds = selectedRows.map((element) => element.id)
    const response = await resetSelected(ticketIds)
    if(response && response.success){
        clearSelectedRows()
    }
  }

  return (
    <div className="query-table-controls">
      <TableButton
        onClick={() => {
          refreshData();
        }}
        disabled={isFetching}
      >
        <IoMdRefresh />
      </TableButton>
      <HasRolePermission
        allowedRoles={[
          "Administrator",
          "ServiceAdmin",
          "ServiceCoach",
          "OfficeAdministrator",
          "CompanyAdministrator"
        ]}
      >
        <TableButton
          title="Export patients to excel file"
          onClick={async () => {
            const columnsToExport = R.pipe(
              R.values,
              R.filter(R.prop("active")),
              R.map(R.prop("field"))
            )(tableSettings);
            await dispatch(exportFirstTimeRegistration(query, columnsToExport));
          }}
        >
          <FaFileExcelO />
        </TableButton>
      </HasRolePermission>
      <HasRolePermission allowedRoles={["Administrator"]}>
        <PureModal
          renderTrigger={({ openModal }) => (
            <TableButton
              disabled={selectedRows.length === 0}
              onClick={openModal}
              title={`Bulk assign (${selectedRows.length} selected)`}
            >
              <IoMdEdit />
            </TableButton>
          )}
          renderContent={({ closeModal }) => (
            <BulkFirstTimeRegistrationAssignModal
              patientIds={selectedRows.map(x => x.id)}
              onUpdateError={() => {
                clearSelectedRows();
              }}
              onUpdateSuccess={() => {
                closeModal();
                clearSelectedRows();
                refreshData();
              }}
            />
          )}
        />
      </HasRolePermission>
      <HasRolePermission allowedRoles={["Administrator"]}>
        {
            resetting ?
            <Spinner/>
            :
            <TableButton
                disabled={selectedRows.length === 0}
                onClick={reset}
                title={`Reset (${selectedRows.length} selected)`}
            >
                <IoArrowReturnLeft />
            </TableButton>
        }
        
      </HasRolePermission>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        {selectedRows.length > 0 && (
          <span>Selected {selectedRows.length} Patients</span>
        )}
      </div>
    </div>
  );
};

export default PatientsTableControls;

PatientsTableControls.propTypes = {
  refreshData: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  selectedRows: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string, patient_name: PropTypes.string })
  ).isRequired,
  clearSelectedRows: PropTypes.func.isRequired,
  query: PropTypes.object.isRequired,
  resetSelected: PropTypes.func.isRequired,
  resetting: PropTypes.bool.isRequired,
};
