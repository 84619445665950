import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useFetch } from 'hooks';
import { path } from "ramda";
import moment from "moment";

import { get, post } from "utils/api";
import { getApiErrorMessage, formatPhone } from "utils/misc";
import { Spinner } from 'components/ui';
import { errorMessage } from "actions/message";
import { FaFileExcelO } from "react-icons/lib/fa";
import { browserHistory } from "browser-history";
import IoMdCheckmark from 'react-icons/lib/io/checkmark';
import { setPatientBatchIds } from "actions/batch";
import { createTab } from "actions/system-tray";
import useRowSelect, {
    SelectRowContext
} from "hooks/useRowSelectNew";

const formatDate = date => date ? moment(date).format("MM/DD/YYYY") : null;

export default function PatientDataByCategory ({ dateFrom, dateTo, companyId, category, categoryName, value, filterName, closeModal, inActionView, fromTasksChart }){

    const history = useHistory()

    const dispatch = useDispatch();
    const {
        response : patientList,
        fetchData : fetchPatientList,
        isFetching : fetchingPatientList
    } = useFetch({
        apiFn: () => get(`compliance-dashboard/patients-by-category`, { Category: category, Value: value, DateFrom: dateFrom, DateTo: dateTo, CompanyId: companyId } ),
        defaultValue: null,
        transformError: path(["response", "body", "status"]),
        onError: error => {
            dispatch(errorMessage(`Category: ${categoryName} filter: ${value} ERROR: , ${getApiErrorMessage(error)}`));
            closeModal();
          }
    })

    const {
        fetchData : exportPatientsByCategory,
        isFetching : exportingPatientsByCategory
    } = useFetch({
        apiFn: () => post(`compliance-dashboard/export-patients-by-category`, { Category: category, Value: value, DateFrom: dateFrom, DateTo: dateTo, CompanyId: companyId } ),
        defaultValue: null,
        transformError: path(["response", "body", "status"]),
        onError: error => {
            dispatch(errorMessage(`Error when trying to export patients by category. ${getApiErrorMessage(error)}`));
          }
    })

    async function initialData() {
        await fetchPatientList()
    }

    async function exportPatients() {
        const exportSuccessful = await exportPatientsByCategory()
        if(exportSuccessful){
            browserHistory.push("/exports");
        }
    }

    React.useEffect(() => {
        initialData();
      }, []);

    const {
        toggleAllRows,
        isRowSelected,
        toggleRow,
        clearSelectedRows,
        selectedRows
    } = useRowSelect([], "id", {});

    const currentUnalteredRoute = history.location.pathname

    return(
        <SelectRowContext.Provider
          value={{
            toggleAllRows,
            toggleRow,
            selectedRows,
            isRowSelected,
            clearSelectedRows
          }}
        >
            <div className='special-fields' style={{height: "100%"}}>
                <header className="page-header">
                    <div className="page-header-title">
                        <h1>
                            {
                                inActionView ?
                                <span className="page-title">
                                    {`Patients for the category "${categoryName}"`}
                                </span>
                                :
                                <span className="page-title">
                                    {`Patients for the category "${categoryName}", filtered by "${filterName}"`}
                                </span>
                            }
                        </h1>
                    </div>
                    <div>
                        {
                            exportingPatientsByCategory &&
                            <Spinner />
                        }
                    </div>
                    <div
                        title="Export patients to excel file"
                        className="patient-list-export"
                        style={(exportingPatientsByCategory || fetchingPatientList || patientList?.length === 0) ? {opacity: "0.5", pointerEvents: "none"} : undefined}
                        onClick={() => {
                            exportPatients()
                        }}
                    >
                        <FaFileExcelO />
                    </div>
                </header>

                <div style={{overflowX: "scroll", height: "93%"}}>
                    <div
                        style={{
                            display: "grid",
                            gridGap: 10,
                            alignItems: "left",
                            height: "fit-content"
                        }}
                    >
                        {!fetchingPatientList?(
                            patientList && patientList.length > 0?(
                                <table className="large-12 ">
                                    <thead>
                                        <tr>
                                            <th>Patient name</th>
                                            <th>Account number</th>
                                            <th>Date Of Birth</th>
                                            <th>Insurance</th>
                                            <th>Branch</th>
                                            <th>Status</th>
                                            <th>Compliance coach</th>
                                            <th>Is Compliant</th>
                                            <th>Date Compliance Met</th>
                                            <th>Compliance %</th>
                                            <th>Doctor</th>
                                            <th>Doctor Phone Number</th>
                                            <th>MD Follow Up Date</th>
                                            <th>Therapist</th>
                                            <th>Setup Date</th>
                                            <th>Days since setup</th>
                                            <th>Setup type</th>
                                            <th>Pap type</th>
                                            <th>Contact type</th>
                                            <th>High CAI</th>
                                            <th>High AHI</th>
                                            <th>Post 90 Day</th>
                                            <th>Software</th>
                                            <th>Latest contact</th>
                                            <th>Outreach date</th>
                                            <th>Outreach cycle</th>
                                            <th>Mask ID</th>
                                            <th>HCPCS Mask</th>
                                            <th>Description Mask</th>
                                            <th>Machine ID</th>
                                            <th>HCPCS Machine</th>
                                            <th>Description Machine</th>
                                            { fromTasksChart && <th>Task Status</th> }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            patientList.map((patient) =>(
                                                <tr key={patient.PatientGuid}>
                                                    <td>
                                                        <span
                                                            onClick={() => {
                                                                history.replace(`${currentUnalteredRoute}?selectedTab=Compliance Data&fromDashboard=true`);
                                                                dispatch(
                                                                    setPatientBatchIds(
                                                                        [patient.PatientGuid],
                                                                        [patient.PatientName]
                                                                    )
                                                                );
                                                                dispatch(
                                                                    createTab({
                                                                        type: "patients",
                                                                        key: "patients",
                                                                        label: `Patients (${selectedRows.length})`,
                                                                        meta: { ids: [patient.PatientGuid] }
                                                                    })
                                                                );
                                                                clearSelectedRows();
                                                            }}
                                                            style={{color: "#4281A6", lineHeight: "inherit", textDecoration: "none", cursor: "pointer"}}
                                                        >
                                                            {patient.PatientName}
                                                        </span>
                                                    </td>
                                                    <td>{patient.AccountNumber}</td>
                                                    <td>{formatDate(patient.DateOfBirth)}</td>
                                                    <td>{patient.Insurance}</td>
                                                    <td>{patient.BranchName}</td>
                                                    <td>{patient.Status}</td>
                                                    <td>{patient.ComplianceCoach}</td>
                                                    <td>{patient.IsCompliant}</td>
                                                    <td>{formatDate(patient.DateComplianceMet)}</td>
                                                    <td>{patient.CompliancePercentage}</td>
                                                    <td>{patient.Physician}</td>
                                                    <td>{formatPhone(patient.DoctorPhoneNumber)}</td>
                                                    <td>{formatDate(patient.MDFollowUpDate)}</td>
                                                    <td>{patient.Therapist}</td>
                                                    <td>{formatDate(patient.SetupDate)}</td>
                                                    <td>{patient.DaysSinceSetup}</td>
                                                    <td>{patient.SetupType}</td>
                                                    <td>{patient.PapType}</td>
                                                    <td>{patient.ContactType}</td>
                                                    <td>{patient.HighCAI ? <IoMdCheckmark /> : null}</td>
                                                    <td>{patient.HighAHI ? <IoMdCheckmark /> : null}</td>
                                                    <td>{patient.Post90Days ? <IoMdCheckmark /> : null}</td>
                                                    <td>{patient.Software}</td>
                                                    <td>{formatDate(patient.LatestContact)}</td>
                                                    <td>{formatDate(patient.OutreachDate)}</td>
                                                    <td>{patient.OutreachCycle}</td>
                                                    <td>{patient.MaskID}</td>
                                                    <td>{patient.HCPCSMask}</td>
                                                    <td>{patient.DescriptionMask}</td>
                                                    <td>{patient.MachineID}</td>
                                                    <td>{patient.HCPCSMachine}</td>
                                                    <td>{patient.DescriptionMachine}</td>
                                                    {fromTasksChart && <td>{patient.TaskStatus}</td>}
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            ) : (<p>No records found for applied filters</p>)
                        ) : (<Spinner />)
                        }
                    </div>
                </div>
            </div>
        </SelectRowContext.Provider>
    )
}

PatientDataByCategory.propTypes = {
    categoryName: PropTypes.string.isRequired,
    category: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
    closeModal: PropTypes.func.isRequired,
    dateFrom: PropTypes.oneOfType([PropTypes.string, PropTypes.date]),
    dateTo: PropTypes.oneOfType([PropTypes.string, PropTypes.date]),
    companyId: PropTypes.string,
    value: PropTypes.number, 
    filterName: PropTypes.string, 
    inActionView: PropTypes.bool, 
    fromTasksChart: PropTypes.bool
}