import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import useLocalQuery, { Table } from "hooks/useQuery/local-query";
import InventoryTableRow from "components/screens/inventory/inventory-table/inventory-table-row";
import CompatibleItemsInventoryTableRow from "./compatible-items-inventory-table-row";
import CompatibleItemsCreateForm from "./compatible-items-create-form";
import HasRolePermssion from "components/shared/has-role-permission";

const buttonStyle = (mode, active) => ({
  background: mode === active ? "rgba(66,129,166, 1)" : "rgba(66,129,166, 0.4)",
  fontWeight: mode === active ? "bold" : "normal",
});

const CompatibleItemsTable = ({
  children,
  parents,
  inventoryItem,
  masterProfile,
  manufacturerOptions,
  equipmentTypeOptions,
}) => {
  const [mode, setMode] = useState("parents");
  return (
    <div className="table">
      <div className="header">
        <button
          style={buttonStyle(mode, "parents")}
          onClick={() => setMode("parents")}
        >
          Compatible Parent Items
        </button>
        <button
          style={buttonStyle(mode, "children")}
          onClick={() => setMode("children")}
        >
          Compatible Child Items
        </button>
      </div>
      <InventoryItemsTable
        inventoryItems={mode === "children" ? children : parents}
        equipmentTypeOptions={equipmentTypeOptions}
        manufacturerOptions={manufacturerOptions}
        masterProfile={masterProfile}
        inventoryItem={inventoryItem}
        mode={mode}
      />
    </div>
  );
};

CompatibleItemsTable.propTypes = {
  inventoryItem: PropTypes.shape({
    children: PropTypes.arrayOf(PropTypes.string),
  }),
  children: PropTypes.arrayOf(PropTypes.object),
  parents: PropTypes.arrayOf(PropTypes.object),
  equipmentTypeOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  manufacturerOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  masterProfile: PropTypes.bool
};

export default connect((state, props) => {
  const { inventoryItem } = props;
  return {
    children: selectors.getInventoryItemsByIds(state, inventoryItem.children),
    parents: selectors.getInventoryItemParentsById(state, inventoryItem.id),
    manufacturerOptions: selectors.getManufacturerOptions(state),
    equipmentTypeOptions: selectors.getEquipmentTypeOptions(state),
  };
})(CompatibleItemsTable);

const InventoryItemsTable = ({
  equipmentTypeOptions,
  manufacturerOptions,
  inventoryItems,
  inventoryItem,
  masterProfile,
  mode
}) => {
  const { rows, query, queryDispatcher } = useLocalQuery(inventoryItems, {
    sort: { by: "name", direction: "asc" },
    filters: {},
  });
  return (
    <>
      {masterProfile && ((mode === "children" && inventoryItem.parent) || (mode === "parents" && !inventoryItem.parent)) ? 
        <HasRolePermssion allowedRoles={["Administrator"]}>
          <CompatibleItemsCreateForm mode={mode} inventoryItem={inventoryItem} /> 
        </HasRolePermssion>
          : ""}
      <Table
        TableRow={masterProfile ? CompatibleItemsInventoryTableRow : InventoryTableRow}
        containerClass="query-table inventory-table"
        columns={masterProfile 
          ? [
            {
              header: "Name",
              field: "name",
              style: { width: "500px" },
            },
            {
              header: "Product Id",
              field: "product_id",
            },
            {
              header: "HCPCS",
              field: "hcpcs",
            },
            {
              header: "Equipment Type",
              field: "product_type",
              filterKey: "product_type",
              filters: equipmentTypeOptions,
            },
            {
              header: "Manufacturer",
              field: "manufacturer",
              filterKey: "manufacturer_id",
              filters: manufacturerOptions,
            },
            {
              header: "Avg Reimbursment",
              field: "avgReimbursment",
            },
            {
              header: "Active",
              field: "productActive",
              filters: [
                {
                  value: true,
                  text: "Yes",
                },
                {
                  value: false,
                  text: "No",
                },
              ],
            },
            {
              header: "Delete Relationship",
              field: "deleteRelationship",
            }
          ]
          : [
            {
              header: "Name",
              field: "name",
              style: { width: "500px" },
            },
            {
              header: "Product Id",
              field: "product_id",
            },
            {
              header: "HCPCS",
              field: "hcpcs",
            },
            {
              header: "Equipment Type",
              field: "product_type",
              filterKey: "product_type",
              filters: equipmentTypeOptions,
            },
            {
              header: "Manufacturer",
              field: "manufacturer",
              filterKey: "manufacturer_id",
              filters: manufacturerOptions,
            },
            {
              header: "Avg Reimbursment",
              field: "avgReimbursment",
            },
            {
              header: "Active",
              field: "productActive",
              filters: [
                {
                  value: true,
                  text: "Yes",
                },
                {
                value: false,
                text: "No",
              },
            ],
          },
        ]}
        query={query}
        queryDispatcher={queryDispatcher}
        rows={rows}
        rowKey="id"
        />
    </>
  );
};

InventoryItemsTable.propTypes = {
  equipmentTypeOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  manufacturerOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  inventoryItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  inventoryItem: PropTypes.object,
  masterProfile: PropTypes.bool,
  mode: PropTypes.string
};
