import React, { useEffect, useState } from 'react'
import PropTypes from "prop-types";
import { useFetch } from "hooks";
import { get, put } from "utils/api";
import { Spinner } from 'components/ui';
import { errorMessage } from "actions/message";
import { getApiErrorMessageCustomPathEnd } from "utils/misc";
import { useDispatch } from 'react-redux';


const BulkFirstTimeRegistrationAssignModal = ({ patientIds, onUpdateSuccess }) => {

  const dispatch = useDispatch()
  const [availableAdmins, setAvailableAdmins] = useState([]);
  const [selectedAdmin, setSelectedAdmin] = useState({ text: "Select Coach", value: "" })

  const { fetchData: fetchAdmins, isFetching } = useFetch({
    apiFn: () => get("first-time-registration-dashboard/sas-company-administrators"),
    defaultValue: [],
    onError: error => {
        dispatch(errorMessage(`Failed to fetch admins: ${getApiErrorMessageCustomPathEnd(error, "message")}`));
    }
  });

  const { fetchData: confirmAssign, isFetching : confirmingAssign } = useFetch({
    apiFn: () => put("first-time-registration-dashboard/bulk-assign", {Records: patientIds, UserId: selectedAdmin.value}),
    defaultValue: [],
    onError: error => {
        dispatch(errorMessage(`Failed to assign registers: ${getApiErrorMessageCustomPathEnd(error, "message")}`));
    },
    onSuccess: () => onUpdateSuccess()
  });

  const getAdmins = async () =>  {
    const res = await fetchAdmins()
    if(res){
      setAvailableAdmins(res.Result)
    }
  }

  const handleSelectChange = (event) => {
    const value = event.target.value;
    const text = event.target.options[event.target.selectedIndex].text;
    setSelectedAdmin({ text: text, value: value });
  };

  useEffect(() => {
    getAdmins()
  },[])

  return (
    <div style={{padding: "1em"}}>
      <h4 style={{color: "#87d59e"}}>Assign to</h4>
      <div>
          <hr style={{marginBottom: 0}} />
          {isFetching
            ? <Spinner />
            : <select value={selectedAdmin.value} onChange={e => handleSelectChange(e) }>
                <option value="" >Select Coach</option>
                {availableAdmins?.map((coach) => (
                    <option key={coach.value} value={coach.value}>
                      {coach.text}
                    </option>
                ))}
            </select>
          }
      </div>
      <div>
          {
            confirmingAssign ?
                <Spinner />
            :
                <button 
                    onClick={confirmAssign}
                    disabled={isFetching || selectedAdmin.value == ""}
                    className='select-coach-for-appointment-button'
                >
                    Confirm Assignation
                </button>
          }
        </div>
    </div>
  )
}

BulkFirstTimeRegistrationAssignModal.propTypes = {
    patientIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    onUpdateSuccess: PropTypes.func
};

export default BulkFirstTimeRegistrationAssignModal;
