import { getUsers, isUsersFetching } from "reducers";
import PropTypes from "prop-types";
import {
  USER_FETCH_ACTION_CREATORS,
  SLEEP_COACH,  
  exportUserTable
} from 'actions/users'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import DataColumnFilterTable from 'components/ui/data-column-filter-table'
import { InlineWaiting, PageHeader } from 'components/ui'
import capitalize from 'lodash/capitalize'

const [fetchUsers, invalidateUsers] = USER_FETCH_ACTION_CREATORS[SLEEP_COACH]
class MultiFilterSleepCoachTable extends Component {
  
  componentDidMount() {
    this.handleRefresh();
  }
 
  handleRefresh = () => {
    const { dispatch } = this.props
    dispatch(invalidateUsers())
    dispatch(fetchUsers())
  }

  handleExport = (filteredRows) => {
    this.props.dispatch(exportUserTable('sleep_coaches', filteredRows))
  }

  render() {
    const {
      users,      
      isFetching
    } = this.props
    const nameSort = ({name}) => capitalize(name)

    return (
      <div>
        <PageHeader
        title= "Sleep Coaches"
        />
        <DataColumnFilterTable
          columns={[
            {
              title: 'Sort by name',
              header: 'Name',
              field: user => user.name,
              format: user => (
                <Link to={`/users/${user.id}`}>                
                  {user.name}
                </Link>
              ),
            
              filterTest: 'name'
            },                       
            {
              title: 'Sort by Assigned Team',
              header: 'Team',
              field: user => user.team_name,
            },
            {
              title: 'Sort by Active Patients',
              header: 'Active Patients',
              field: user => user.active_patients,
              numerical: true
            },
            {
              title: 'Sort by Follow Ups',
              header: 'Follow Ups',
              field: user => user.follow_ups,
              numerical: true
            },
            {
              title: 'Sort by Second Follow Ups',
              header: 'Second Follow Ups',
              field: user => user.second_follow_ups,
              numerical: true
            },
            {
              title: 'Sort by Eligible',
              header: 'Eligible',
              field: user => user.eligible,
              numerical: true
            },
            {
              title: 'Sort by Sleep Coach Active',
              header: 'Active',
              field: user => user.active.toString(),
              boolean: true,
              
            }
          ]}
          rows={users}
          onRefresh = {this.handleRefresh}
          onExport= {this.handleExport}
          initialSort={{ iteratee: nameSort }}
          initialFilters={[{
            input: '',
            columnIndex: 6,
            testOpKey: 'REG_EXP',
            selectActiveBoolean: 'true'
          }]} />
        {isFetching && <InlineWaiting reason='Fetching Sleep Coaches...' />}
      </div>
    )
  }
}

export default connect(state => ({
  users: getUsers(state, SLEEP_COACH),
  isFetching: isUsersFetching(state, SLEEP_COACH),
}))(MultiFilterSleepCoachTable)

MultiFilterSleepCoachTable.propTypes = {
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  isFetching: PropTypes.bool,
  dispatch: PropTypes.func.isRequired
};
