import { selectors } from "reducers";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { errorMessage } from "actions/message";
import "./styles.css";
import { useFetch } from "hooks";
import { put } from "utils/api";
import React, { useEffect, useState } from "react";
import { PureModal } from "components/ui";
import IoMdEdit from "react-icons/lib/io/edit";
import NotesShowEditModal from "./NotesShowEditModal";
import { IoEye } from "react-icons/lib/io";
import HasRolePermission from "components/shared/has-role-permission";
import BulkFirstTimeRegistrationAssignModal from "../first-time-registration-dashboard/bulk-first-time-registration-assign-modal";

const Header = ({ patientData, ticketNumberId, userHasSelectedPatient, selectedPatient, getUserData }) => {
  const dispatch = useDispatch();
  const userId = useSelector(selectors.getUserId);

  const [note, setNote] = useState("")

  const [isWorked, setIsWorked] = useState(false)
  const [matchedPatientId, setMatchedPatientId] = useState("")
  const [matchButtonDisabled, setMatchButtonDisabled] = useState(false)
  const [workedButtonDisabled, setWorkedButtonDisabled] = useState(true)

  const { fetchData: workCase, isFetching: workingCase } = useFetch({
    apiFn: () => put(`first-time-registration-dashboard/${userId}/work/${ticketNumberId}`),
    defaultValue: [],
    onError: err => {
        dispatch(errorMessage(`Failed to work case: ${err}`))
    },
    onSuccess: () => {
        setWorkedButtonDisabled(true)
        getUserData()
    }
  });

  const { fetchData: confirmMatch, isFetching: confirmingMatch } = useFetch({
    apiFn: () => put(`first-time-registration-dashboard/${ticketNumberId}/match/${selectedPatient}`),
    defaultValue: [],
    onError: err => dispatch(errorMessage(`Failed to match register with patient: ${err}`)),
    onSuccess: () => {
        setMatchButtonDisabled(true)
        setWorkedButtonDisabled(false)
        getUserData()
    }
  });

  useEffect(() => {
    if(patientData){
        setIsWorked(patientData.Worked)
        setMatchedPatientId(patientData.MatchedPatientId)
        setNote(patientData.Notes)

        if(!patientData.Worked && patientData.MatchedPatientId){
            setWorkedButtonDisabled(false)
        }

    }
  }, [patientData]);

  const noMatchFound = () => {
    setMatchButtonDisabled(true)
    setWorkedButtonDisabled(false)
  }

  const asigneeName = () => {
    if(patientData?.AssigneeName){
        return patientData?.AssigneeName
    } else {
        return "Not Assigned"
    }
  }
    
  return (
    <div className="header-borders">
        <h2 className="header-title">{patientData?.Name}</h2>
        <div className="register-container">
            <div className="register-data">
                <div>
                    <p>
                        <strong>Email:</strong> {patientData?.Email}
                    </p>
                    <p>
                        <strong>Phone number:</strong> {patientData?.PhoneNumber}
                    </p>   
                </div>
                <div>
                    <p>
                        <strong>Date of birth:</strong> {patientData?.DateOfBirth}
                    </p>
                    <p>
                        <strong>Registration Date:</strong> {patientData?.RegistrationDate}
                    </p>
                </div>
            </div>
            <div className="register-action">
                <div className="editable-header-fields">
                    <p>
                        <strong>Text:</strong>
                    </p>
                    <PureModal
                        renderTrigger={({ openModal }) => (
                            <p onClick={openModal} style={{cursor: "pointer", marginLeft: "10px"}}>
                                {isWorked ? <IoEye/> : <IoMdEdit/>}
                            </p>
                        )}
                        renderContent={({ closeModal }) => (
                            <NotesShowEditModal
                                notes={note}
                                setNote={setNote}
                                closeModal={closeModal}
                                isWorked={isWorked}
                                ticketNumberId={ticketNumberId}
                            />
                        )}
                    />
                </div>
                <div className="editable-header-fields">
                    <p>
                        <strong>Assignee:</strong> {asigneeName()}
                    </p>
                    {
                        (patientData?.MatchedPatientId || patientData?.Worked || matchButtonDisabled) ?
                            null
                        :
                        <HasRolePermission allowedRoles={["Administrator"]}>
                            <PureModal
                                renderTrigger={({ openModal }) => (
                                    <p onClick={openModal} style={{cursor: "pointer", marginLeft: "10px"}} title={`Edit assignee`}>
                                        <IoMdEdit/>
                                    </p>
                                )}
                                renderContent={({ closeModal }) => (
                                    <BulkFirstTimeRegistrationAssignModal
                                    patientIds={[ticketNumberId]}
                                    onUpdateSuccess={() => {
                                        closeModal();
                                        getUserData();
                                    }}
                                    />
                                )}
                            />
                        </HasRolePermission>
                    }
                </div>
                <div className="match-work-button-container">
                    <button
                        onClick={workCase}
                        disabled={workedButtonDisabled || workingCase || isWorked}
                        className="match-work-button"
                        style={{backgroundColor: workedButtonDisabled || isWorked ? "#808080" : "#008000"}}
                    >
                        {isWorked ? "Worked" : "Work"}
                    </button>
                    <button
                        onClick={() => userHasSelectedPatient ? confirmMatch() : noMatchFound()}
                        disabled={matchButtonDisabled || confirmingMatch || isWorked || matchedPatientId}
                        className="match-work-button"
                        style={{backgroundColor: matchButtonDisabled || matchedPatientId || isWorked ? "#808080" : userHasSelectedPatient ? "#008000" : "#FF0000"}}
                    >
                        {matchedPatientId ? "Matched" : isWorked ? "Not Matched" : userHasSelectedPatient ? "Match" : "No Match"}
                    </button>
                </div>
            </div>
        </div>
    </div>
  );
};

export default Header;

Header.propTypes = {
  patientData: PropTypes.shape({
    Name: PropTypes.string,
    Email: PropTypes.string,
    PhoneNumber: PropTypes.string,
    RegistrationDate: PropTypes.string,
    DateOfBirth: PropTypes.string,
    CanAssign: PropTypes.bool,
    AssigneeId: PropTypes.string,
    AssigneeName: PropTypes.string,
    Worked: PropTypes.bool,
    MatchedPatientId: PropTypes.string,
    Notes: PropTypes.string,
  }),
  userHasSelectedPatient: PropTypes.bool,
  ticketNumberId: PropTypes.string,
  selectedPatient: PropTypes.string.isRequired,
  getUserData: PropTypes.func.isRequired
};
