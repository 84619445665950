import React, { useEffect, useState } from "react";
import { PageHeader } from "components/ui";
import useApiQuery, { Table } from "hooks/useQuery/api-query";
import { connect, useDispatch, useSelector } from "react-redux";
import { selectors } from "reducers";
import { path } from "ramda";
import PatientsTableRow from "./table-row";
import PatientsTableControls from "./table-controls";
import useRowSelect, {
  SelectRowContext,
  RowSelectHeaderCell
} from "hooks/useRowSelectNew";
import usePatientsTableSettings, {
  PatientsTableSettingsContext
} from "./settings";
import { useFetch } from "hooks";
import { get, put } from "utils/api";
import * as msg from "actions/message";
import { getApiErrorMessage } from "utils/misc";
import { yesNoOptions } from "utils/enum";
import { setSort, updateFilters } from "hooks/useQuery/actions";

const FirstTimeRegistrationDashboard = () => {
  const user = useSelector(selectors.getUser);
  const dispatch = useDispatch();

  const initialQuery = {
    sort: {direction: "desc", by: "registrationDate"},
    filters: {
      worked: [false],
      matched: [false]
    },
    pagination: { page: 1, per: 150 }
  };
  const { query, queryDispatcher, rows, data, isFetching, fetchData } =
    useApiQuery("first-time-registration-dashboard", initialQuery, "Results");
  const {
    toggleAllRows,
    isRowSelected,
    toggleRow,
    clearSelectedRows,
    selectedRows
  } = useRowSelect(rows, "ticketNumber", query, row => ({
    patient_name: `${row.first_name} ${row.last_name}`
  }));

  const [asigneeFilterOptions, setAsigneeFilterOptions] =
    useState([]);

  const {
    fetchData: fetchAsignees,
  } = useFetch({
    apiFn: () => get(`first-time-registration-dashboard/sas-company-administrators`),
    defaultValue: {},
    transformError: path(["response", "body", "status"]),
    onError: error => {
      dispatch(msg.errorMessage(`Failed to get asignees: ${getApiErrorMessage(error)}`));
    }
  })

  const {
    fetchData: resetSelected, isFetching: resetting
  } = useFetch({
    apiFn: (ticketIds) => put(`first-time-registration-dashboard/bulk-reset`, ticketIds),
    defaultValue: {},
    transformError: path(["response", "body", "status"]),
    onError: error => {
      dispatch(msg.errorMessage(`Failed to reset selected registers: ${getApiErrorMessage(error)}`));
    },
    onSuccess: () => {
      dispatch(msg.message(`Registers successfully reset`));
      fetchData(query) 
    }
  })

  useEffect(() => {
    getAsignees();
  }, []);

  const getAsignees = async () => {
    const { Result } = await fetchAsignees();
    if (Result) {
      const asigneesProcessed = Result.map(asignee => ({
        value: asignee.value,
        text: asignee.text,
        GUID: asignee.value
      }));
      setAsigneeFilterOptions(asigneesProcessed);
    }
  };

  const tableOptions = usePatientsTableSettings(query, queryDispatcher);

  const {
    isColumnVisible,
    tableSettings,
    updateTableSettings,
    resetTableSettings,
    columns
  } = tableOptions;
  return (
    <div className="patients-table-page">
      <PageHeader title="First Time Registration" />
      <PatientsTableSettingsContext.Provider
        value={{
          columns,
          isColumnVisible,
          resetTableSettings,
          tableSettings,
          updateTableSettings
        }}
      >
        <SelectRowContext.Provider
          value={{
            toggleAllRows,
            toggleRow,
            selectedRows,
            isRowSelected,
            clearSelectedRows
          }}
        >
          <Table
            query={query}
            data={data}
            queryDispatcher={queryDispatcher}
            isFetching={isFetching}
            containerClass="query-table patients-table dynamic-columns"
            columns={[
                ... user.role == "Administrator" ? [{header: "All", field: "all", Cell: RowSelectHeaderCell}] : [],
                {
                    header: "ID",
                    field: "ticketNumber"
                },
                {
                    header: "Email",
                    field: "email"
                },
                {
                    header: "Registration Date",
                    field: "registrationDate"
                },
                {
                    header: "Worked",
                    field: "worked",
                    filters: yesNoOptions
                },
                {
                    header: "Asignee",
                    field: "asignee",
                    filters: [{value: "null", text: "Not Assigned", GUID: "null"}, ...asigneeFilterOptions ]
                },
                {
                    header: "Assigned date",
                    field: "assignedDate"
                },
                {
                    header: "Worker",
                    field: "worker",
                    filters: [{value: "null", text: "No Worker", GUID: "null"}, {value: "System", text: "System", GUID: "System"}, ...asigneeFilterOptions ]
                },
                {
                    header: "Worked Date",
                    field: "workedDate"
                },
                {
                    header: "Matched",
                    field: "matched",
                    filters: yesNoOptions
                },
                {
                    header: "Matched Date",
                    field: "matchedDate",
                },
                {
                    header: "Notes",
                    field: "notes",
                },
            ]}
            rows={rows}
            TableRow={PatientsTableRow}
            rowKey="id"
            perPageOptions={[
              { text: "50", value: 50 },
              { text: "150", value: 150 },
              { text: "500", value: 500 }
            ]}
            Controls={
              <PatientsTableControls
                query={query}
                selectedRows={selectedRows}
                clearSelectedRows={clearSelectedRows}
                isFetching={isFetching}
                refreshData={() => {
                    queryDispatcher(
                        updateFilters(initialQuery.filters)
                    )
                    queryDispatcher(
                        setSort(initialQuery.sort)
                    )
                }}
                resetSelected={resetSelected}
                resetting={resetting}
              />
            }
          />
        </SelectRowContext.Provider>
      </PatientsTableSettingsContext.Provider>
    </div>
  );
};


export default connect(null)(FirstTimeRegistrationDashboard);
