import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import { useSort } from "hooks";
import { Link } from "react-router-dom";
import TableSortableHeader from "components/ui/table-sortable-header";
import PureModal from "components/ui/modal/pure-modal";
import { aLink } from "utils/styles";
import NoMaskReasonBreakdown from "./no-mask-reason-breakdown";
import HasRolePermission from "components/shared/has-role-permission";

const TeamCoachOrdersTable = ({ data }) => {
  const {
    ascending,
    sortBy,
    getSortedArray,
    toggleSortDirection,
    setSortBy
  } = useSort({ by: "Coach", ascending: false });
  return (
    <table>
      <TableSortableHeader
        columns={[
          { key: "Coach", text: "Name" },
          { key: "Entered_Masks_Sold", text: "Masks" },
          { key: "Entered_Seals_Sold", text: "Seals" },
          { key: "Entered_Masks_Ratio", text: "Masks Ratio" },
          { key: "Entered_Seals_Ratio", text: "Seals Ratio" },
          { key: "EnteredOrders", text: "Total Orders" },
          { key: "Net_Orders", text: "Net Orders" }
        ]}
        sortBy={sortBy}
        ascending={ascending}
        setSortBy={setSortBy}
        toggleSortDirection={toggleSortDirection}
      />
      <tbody>
        {getSortedArray(data).map(d => (
          <tr key={d.COACH_GUID}>
            <td>
              <Link to={`/users/${d.COACH_GUID}`}>{d.Coach}</Link>
            </td>
            <td>{d.Entered_Masks_Sold}</td>
            <td>{d.Entered_Seals_Sold}</td>
            <HasRolePermission
              allowedRoles={["Administrator"]}
              renderElse={<td>{d.Entered_Masks_Ratio}</td>}
            >
              <PureModal
                renderTrigger={({ openModal }) => (
                  <td style={aLink} onClick={openModal}>
                    {d.Entered_Masks_Ratio}
                  </td>
                )}
                windowProps={{ large: true }}
              >
                <NoMaskReasonBreakdown
                  coach_guid={d.COACH_GUID}
                  coach_name={d.Coach}
                />
              </PureModal>
            </HasRolePermission>
            <td>{d.Entered_Seals_Ratio}</td>
            <td>{d.EnteredOrders}</td>
            <td>{d.Net_Orders}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

TeamCoachOrdersTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default connect(state => ({
  data: selectors.getTeamCoachInsights(state)
}))(TeamCoachOrdersTable);
