import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import { fetchUserProfile, fetchUserInsights } from "actions/user-profile";
import { ProfileCard, Waiting } from "components/ui";
import { formatPhone, splitWordAtCase } from "utils/misc";
import UserInsights from "./user-insights";
import { Link, withRouter } from "react-router-dom";
import { browserHistory } from "browser-history";
import MdCheck from "react-icons/lib/md/check";
import MdClose from "react-icons/lib/md/close";
import * as R from "ramda";
import { firstDayOfThisMonth, today } from "utils/dates";
import HasRolePermission from "components/shared/has-role-permission";
import HasAppPermission from "components/shared/has-app-permission";
import moment from "moment";

const getUserType = userProfile => {
  if (userProfile.role === "OfficeAdministrator" && userProfile.admin_elevated)
    return "SleepCoach";
  return userProfile.role;
};

const UserProfile = ({
  match,
  fetchUserProfile,
  userProfile,
  userAccessibleCompanies,
  fetchUserInsights,
  isFetchingUserProfile,
  userRole
}) => {
  const userId = R.path(["params", "id"], match);
  React.useEffect(() => {
    fetchUserProfile(userId);
    fetchUserInsights(userId, firstDayOfThisMonth(), today());
  }, [userId]);
  if (!userProfile && !isFetchingUserProfile) return null;
  return isFetchingUserProfile ? (
    <Waiting />
  ) : (
    <div className="user-profile-container">
      <div className="user-page-header">
        <h1>{`${userProfile.first_name ?? ""} ${userProfile.last_name ?? ""}`}</h1>
        <div className="user-header-controls">
          <Link to={`/users/${userId}/edit`}>
            <button className="default">Edit</button>
          </Link>
          <Link to={`/users/${userId}/logs`}>
            <button className="secondary">Logs</button>
          </Link>
          <button className="warning" onClick={browserHistory.goBack}>
            Close
          </button>
        </div>
      </div>
      <div className="user-profile-info">
        <ProfileCard
          title="Profile"
          items={{
            Email: userProfile.email,
            Active: userProfile.active ? (
              <MdCheck color="green" size={20} title="Yes" />
            ) : (
              <MdClose color="red" size={20} title="No" />
            ),
            "Two-Factor Auth": userProfile.mfa_enabled ? (
              <MdCheck color="green" size={20} title="Yes" />
            ) : (
              <MdClose color="red" size={20} title="No" />
            ),

            "User Type": splitWordAtCase(
              userRole === "Administrator"
                ? userProfile.role
                : getUserType(userProfile)
            ),
            "Compliance Coach" : userProfile.compliance_coach ? (
              <MdCheck color="green" size={20} title="Yes" />
            ) : (
              <MdClose color="red" size={20} title="No" />
            ),
            Phone: formatPhone(userProfile.phone_number),

            Extension: userProfile.extension,
            ...(["Administrator", "SleepCoach"].includes(userProfile.role)
              ? {
                  Team: userProfile.call_group,
                  "Hire Date": userProfile.hire_date
                    ? moment(userProfile.hire_date).format("L")
                    : "",
                  "Employee Id": userProfile.employee_id
                }
              : {
                  Company: R.path(["company", "value"], userProfile)
                }),
            "Accessible Companies": userAccessibleCompanies.sort(),
            ...(["SleepCoach"].includes(userProfile.role)
              ? {
                  "Require Order Review": (
                    <HasRolePermission allowedRoles={["Administrator"]}>
                      {userProfile.require_order_review ? (
                        <MdCheck color="green" size={20} title="Yes" />
                      ) : (
                        <MdClose color="red" size={20} title="No" />
                      )}
                    </HasRolePermission>
                  )
                }
              : {}),
              "Can Be Emailed From Patient Profile" : userProfile.can_be_emailed_from_patient_profile ? (
              <MdCheck color="green" size={20} title="Yes" />
            ) : (
              <MdClose color="red" size={20} title="No" />
            ),

            ...(["Administrator", "SleepCoach"].includes(userProfile.role)
              ? {
                  "Text Chat Enabled": (
                    <HasRolePermission allowedRoles={["Administrator"]}>
                      {userProfile.chat_capable ? (
                        <MdCheck color="green" size={20} title="Yes" />
                      ) : (
                        <MdClose color="red" size={20} title="No" />
                      )}
                    </HasRolePermission>
                  )
                }
              : {}),
            "Contracted Employee" : userProfile.contracted_employee ? (
              <MdCheck color="green" size={20} title="Yes" />
            ) : (
              <MdClose color="red" size={20} title="No" />
            ),
            "Speaks Spanish": userProfile.speaks_spanish ? (
              <MdCheck color="green" size={20} title="Yes" />
            ) : (
              <MdClose color="red" size={20} title="No" />
            )
          }}
        />
        {["SleepCoach"].includes(getUserType(userProfile)) && (
          <React.Fragment>
            <ProfileCard
              parentClassOveride={" "}
              title="Patients"
              items={{
                Active: userProfile.active_patients,
                Inactive: userProfile.inactive_patients,
                Eligible: userProfile.eligible,
                "Follow-ups": userProfile.follow_ups
              }}
            />
            <ProfileCard
              parentClassOveride={" "}
              title="Contact Types"
              items={{
                Emails: userProfile.emails_count,
                Calls: userProfile.manual_calls_count,
                Texts: userProfile.text_messages_count,
                App: userProfile.app_count
              }}
            />
          </React.Fragment>
        )}
      </div>
      {userProfile && !["Therapist"].includes(userProfile.role) && (
        <HasAppPermission permission="orderSupplies">
          <UserInsights userId={userId} userType={getUserType(userProfile)} />
        </HasAppPermission>
      )}
    </div>
  );
};

UserProfile.propTypes = {
  fetchUserProfile: PropTypes.func.isRequired,
  userProfile: PropTypes.object,
  userAccessibleCompanies: PropTypes.arrayOf(PropTypes.string).isRequired,
  isFetchingUserProfile: PropTypes.bool,
  fetchUserInsights: PropTypes.func.isRequired,
  userRole: PropTypes.string.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  }).isRequired
};

export default connect(
  state => ({
    userRole: selectors.getUserRole(state),
    userProfile: selectors.getUserProfile(state),
    userAccessibleCompanies: selectors.getUserAccessibleCompanies(state),
    isFetchingUserProfile: selectors.getIsFetchingUserProfile(state)
  }),
  { fetchUserProfile, fetchUserInsights }
)(withRouter(UserProfile));
