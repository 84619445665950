import React from "react";
import PropTypes from "prop-types";
import { useSort } from "hooks";
import TableSortableHeader from "components/ui/table-sortable-header";
import { propOr } from "ramda";

const OpenOrdersTable = ({ data }) => {
  const {
    ascending,
    sortBy,
    getSortedArray,
    toggleSortDirection,
    setSortBy
  } = useSort({ by: "coach", ascending: false });
  return (
    <table>
      <TableSortableHeader
        columns={[
          { key: "coach", text: "Name" },
          { key: "new", text: "New Orders" },
          { key: "coach follow up", text: "Follow Up Orders" }
        ]}
        sortBy={sortBy}
        ascending={ascending}
        setSortBy={setSortBy}
        toggleSortDirection={toggleSortDirection}
      />
      <tbody>
        {getSortedArray(data).map(c => (
          <tr key={c.coachGUID}>
            <td>{c.coach}</td>
            <td>{propOr(0, "new", c)}</td>
            <td>{propOr(0, "coach follow up", c)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

OpenOrdersTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default OpenOrdersTable;
