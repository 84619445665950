import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import * as R from "ramda";

const TeamCoachFilter = ({
  setFilters,
  filters,
  sleepCoachOptions = [],
  teamNameOptions
}) => {
  const selectedTeams = R.propOr([], "team_name", filters);
  const selectedSleepCoaches = R.propOr([], "sleep_coach", filters);
  const teamsUnassigned = R.propOr([], "unassigned_team", filters);
  const teamsMisassigned = R.propOr([], "misassigned_team", filters);
 const [searchResultsTeamByCoach, setSearchResultsTeamByCoach] = useState(sleepCoachOptions.map(sco => sco.team_name))
 const [searchResultsCoach, setSearchResultsCoach] = useState(sleepCoachOptions.map(sco => sco.team_name))
  const [searchResultsTeam, setSearchResultsTeam] = useState(teamNameOptions.map(tno => tno.text))
  const [searchTerms, setSearchTerms] = useState("")

  useEffect(()=>{
    if(searchTerms !== ""){
      setSearchResultsTeamByCoach(sleepCoachOptions.filter(sco => sco.name.toLowerCase().includes(searchTerms.toLowerCase())).map(c => c.team_name))
      setSearchResultsTeam(teamNameOptions.filter(tno => tno.text.toLowerCase().includes(searchTerms.toLowerCase())).map(t => t.text))
      setSearchResultsCoach(sleepCoachOptions.filter(sco=> sco.name.toLowerCase().includes(searchTerms.toLowerCase())))
      if(searchTerms.includes("una")){
        setSearchResultsTeam(teamNameOptions.map(tno => tno.text))
      }
      if(searchTerms.includes("mis")){
        setSearchResultsTeam(teamNameOptions.map(tno => tno.text))
      }
    }
    else{
      setSearchResultsTeamByCoach(sleepCoachOptions.map(sco => sco.team_name))
      setSearchResultsTeam(teamNameOptions.map(tno => tno.text))
      setSearchResultsCoach(sleepCoachOptions)
    }
  }, [searchTerms])


  return (
    <React.Fragment>
      <div
        onClick={() => {
          setFilters("team_name");
          setFilters("sleep_coach");
          setFilters("unassigned_team");
          setFilters("misassigned_team");
        }}
        className="filter-pane-command controls"
        >
        Clear Selections
      </div>
      <input 
        type="text" 
        className="searchInputBox-coachfilter"
        onChange={(e) => {setSearchTerms(e.target.value)}}
        placeholder="Search By Team or Coach"
        >
        </input>  
      {teamNameOptions.map(tno => {
       if(searchResultsTeam.includes(tno.text) || searchResultsTeamByCoach.includes(tno.text)){
        return (
        <div key={tno.key}>
          <label className="filter-entry">
            <input
              type="checkbox"
              checked={selectedTeams.includes(tno.key) }
              onChange={({ target }) => {
                if (target.checked) {
                  setFilters(
                    "team_name",
                    R.uniq(R.append(tno.key, selectedTeams))
                  );
                  const coachesToAdd = R.pipe(
                    R.filter(sco => tno.text == sco.team_name),
                    R.map(sc => sc.GUID)
                  )(sleepCoachOptions);
                  setFilters("sleep_coach", R.uniq([...coachesToAdd, ...selectedSleepCoaches]));

                } else {
                  setFilters(
                    "team_name",
                    R.without(tno.key, selectedTeams)
                  );
                    setFilters("sleep_coach",
                    R.without(
                      sleepCoachOptions.filter(sco => tno.text == sco.team_name).map(sc => sc.GUID),
                    selectedSleepCoaches));
                    setFilters("unassigned_team",
                    R.without(tno.key, teamsUnassigned));
                    setFilters("misassigned_team",
                    R.without(tno.key, teamsMisassigned));
                }
              }}
            />
            <span style={{ display: "flex", flex: "1 1 auto" }}>
              {tno.text}
            </span>
          </label>
          {
          searchResultsCoach
            .filter(sco => sco.team_name == tno.text)
            .map(sco => (
              <div key={sco.value}>
                <label className="filter-entry filter-sub-entry">
                  <input
                    type="checkbox"
                    checked={selectedSleepCoaches.includes(sco.GUID)}
                    onChange={({ target }) => {
                      if (target.checked) {
                        setFilters(
                          "team_name",
                          R.uniq(R.append(tno.key, selectedTeams))
                        );
                        setFilters(
                          "sleep_coach",
                          R.uniq(R.append(sco.GUID, selectedSleepCoaches))
                        );
                      } else {
                        setFilters(
                          "sleep_coach",
                          R.without([sco.GUID], selectedSleepCoaches)
                        );
                        const coaches = sleepCoachOptions.filter(sc => selectedSleepCoaches.includes(sc.GUID) && sc.GUID != sco.GUID).filter(sc => sc.team_name === tno.text)
                        if (!coaches.length && !teamsUnassigned.includes(tno.key) && !teamsMisassigned.includes(tno.key)) {

                          setFilters(
                            "team_name",
                            R.without(tno.key, selectedTeams)
                          );
                        }
                      }
                    }}
                  />
                  {sco.name}
                </label>
              </div>
            ))}
          {(searchTerms.toLowerCase().includes("una") || searchTerms === "") ? <div key={"Unassigned_" + tno.key}>
            <label className="filter-entry filter-sub-entry">
              <input
                type="checkbox"
                checked={
                  teamsUnassigned.includes(tno.key)
                }
                onChange={({ target }) => {
                  if (target.checked) {
                    setFilters(
                      "team_name",
                      R.uniq(R.append(tno.key, selectedTeams))
                    );
                    setFilters(
                      "unassigned_team",
                      R.uniq(
                        R.append(tno.key, teamsUnassigned)
                      )
                    );
                  } else {
                    setFilters(
                      "unassigned_team",
                      R.without(
                        tno.key,
                        teamsUnassigned
                      )
                    );     
                    const coaches = sleepCoachOptions.filter(sc => selectedSleepCoaches.includes(sc.GUID)).filter(sc => sc.team_name === tno.text)
                    if (!coaches.length && !teamsMisassigned.includes(tno.key)) {
                      setFilters(
                        "team_name",
                        R.without([tno.key], selectedTeams)
                      );
                    }
                  }
                }}
              />
              Unassigned
            </label>
          </div>
          : null}
          {(searchTerms.toLowerCase().includes("mis") || searchTerms === "") ? <div key={"Misassigned_" + tno.key}>
            <label className="filter-entry filter-sub-entry">
              <input
                type="checkbox"
                checked={
                  teamsMisassigned.includes(tno.key) 
                }
                onChange={({ target }) => {
                  if (target.checked) {
                    setFilters(
                      "team_name",
                      R.uniq(R.append(tno.key, selectedTeams))
                    );
                    setFilters(
                      "misassigned_team",
                      R.uniq(
                        R.append(tno.key, teamsMisassigned)
                      )
                    );
                  } else {
                    setFilters(
                      "misassigned_team",
                      R.without(
                        tno.key,
                        teamsMisassigned
                      )
                    );
                    //find all the sleep coaches where the GUID is included in the selectedSleepCoaches filter array then filter where the team name matches
                    // the teamNameOption text (team name)
                    const coaches = sleepCoachOptions.filter(sc => selectedSleepCoaches.includes(sc.GUID)).filter(sc => sc.team_name === tno.text)
                    if (!coaches.length && !teamsUnassigned.includes(tno.key)) {
                      setFilters(
                        "team_name",
                        R.without([tno.key], selectedTeams)
                      );
                    }
                  }
                }}
              />
              Misassigned
            </label>
          </div>
          : null}
        </div>
      )}})}
    </React.Fragment>
  );
};

export default connect((state, props) => ({
  sleepCoachOptions: selectors.getFormOptionsSleepCoachWithUserFirst(state),
  filters: selectors.getQueryFilters(state, props.queryId)
}))(TeamCoachFilter);

TeamCoachFilter.propTypes = {
  queryId: PropTypes.string.isRequired,
  teamNameOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  setFilters: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  sleepCoachOptions: PropTypes.arrayOf(PropTypes.object).isRequired
};
