import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import { selectors } from "reducers";
import { useSort } from "hooks";
import MdArrowDropdown from "react-icons/lib/md/arrow-drop-down";
import MdArrowUp from "react-icons/lib/md/arrow-drop-up";
import { put } from "utils/api";
import TeamDashboardContext from "../context";
import TableSortableHeader from "components/ui/table-sortable-header";
import { requestTeamInsights } from "actions/team-dashboard";

const AllocationCoachesTable = ({ data }) => {
  const [isMinimized, setMinimized] = useState(false);
  const [isDeallocating, setIsDeallocating] = useState(false);
  const { selectedTeam, requestedDates } = useContext(TeamDashboardContext);
  const { ascending, sortBy, getSortedArray, toggleSortDirection, setSortBy } =
    useSort({ by: "coach_name", ascending: true });
  const dispatch = useDispatch();

  const deallocate = async (userGuid, companyGuid, companyName, coachName) => {
    //Deallocate patients from coach and refetch the team stats
    var body = { coach_guid: userGuid, company_guid: companyGuid };
    setIsDeallocating(true);
    if (
      window.confirm(
        `Are you sure you want to deallocate ${companyName} patients for ${coachName}? Make sure this person is not in the dialer before confirming.`
      )
    ) {
      await put(`patients/autodialer/deallocate_from_company/FE`, body);
      dispatch(
        requestTeamInsights(selectedTeam, {
          start_date: requestedDates.start_date,
          end_date: requestedDates.end_date
        })
      );
      setIsDeallocating(false);
    } else {
      setIsDeallocating(false);
    }
  };

  return (
    <div className="block">
      <div className="block-header">
        <span
          style={{ cursor: "pointer" }}
          onClick={() => setMinimized(!isMinimized)}
        >
          Sleepcoach Allocation
          <i className="flat-icon-button">
            {isMinimized ? <MdArrowDropdown /> : <MdArrowUp />}
          </i>
        </span>
        {/* Can put information bubble here */}
      </div>
      {!isMinimized && (
        <div className="block-content">
          <table>
            <TableSortableHeader
              columns={[
                { key: "coach_name", text: "Sleepcoach" },
                {
                  key: "first_company",
                  text: "Company"
                },
                {
                  key: "total_patients_allocated",
                  text: "Patients Allocated"
                },
                {
                  key: "total_time_allocated",
                  text: "Minutes in Queue"
                },
                {
                  key: "deallocate",
                  text: "Deallocate Patients"
                }
              ]}
              sortBy={sortBy}
              ascending={ascending}
              setSortBy={setSortBy}
              toggleSortDirection={toggleSortDirection}
            />
            <tbody>
              {getSortedArray(data).map(d => (
                <tr key={d.coach_guid}>
                  <td>{d.coach_name}</td>

                  <td>
                    {d.patients_allocated.map(x => (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start"
                        }}
                        key={d.coach_guid + x.company_guid}
                      >
                        <h6>{x.company_name}</h6>
                      </div>
                    ))}
                  </td>
                  <td>
                    {d.patients_allocated.map(x => (
                      <div key={d.coach_guid + x.company_guid + "patients"}>
                        <h6
                          title={`Deallocate patients from ${x.company_name}`}
                          style={{ display: "inline-block" }}
                        >
                          {x.patients_total}
                        </h6>
                      </div>
                    ))}
                  </td>
                  <td>
                    {d.patients_allocated.map(x => (
                      <h6 key={d.coach_guid + x.company_guid + "mins"}>
                        {x.time_allocated}
                      </h6>
                    ))}
                  </td>
                  <td>
                    {d.patients_allocated.map(x => (
                      <h6 key={d.coach_guid + x.company_guid + "deallocate"}>
                        <button
                          className="deallocate-button"
                          title={`Deallocate patients from ${x.company_name}`}
                          onClick={() => {
                            if (x.patients_total == 0 || isDeallocating) return;
                            deallocate(
                              d.coach_guid,
                              x.company_guid,
                              x.company_name,
                              d.coach_name
                            );
                          }}
                        >
                          Deallocate
                        </button>
                      </h6>
                    ))}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

AllocationCoachesTable.defaultProps = {
  data: []
};
AllocationCoachesTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default connect(state => ({
  data: selectors.getCoachAllocation(state)
}))(AllocationCoachesTable);
