import React, { useState } from 'react'
import DataColumnFilterTable from 'components/ui/data-column-filter-table';
import '../pages/companyForms.css'
import IoEdit from "react-icons/lib/io/edit";
import { Link } from 'react-router-dom';
import IoEye from "react-icons/lib/io/eye";
import FormTableCheckbox from './FormTableCheckbox';
import CopyForm from './copy-form';
import AssignFormToPatients from './assign-form-to-patient-confirmation-modal';

import { message } from "actions/message";
import { useDispatch } from 'react-redux';
import PropTypes from "prop-types";


export default function Table({ formList, handleView, idCompany, updateRowArray, assignFormConfirmation, setFormToBeAssignedId }) {

    const dispatch = useDispatch();

    const handleExport = () => {
        dispatch(message("Feature Not implemented yet"));
    }
    const [otherFormIsLoading, setOtherFormIsLoading] = useState(false)

    return (
        <div>
            <DataColumnFilterTable
                columns={[
                    {
                        title: 'Sort by name',
                        header: 'Name',
                        field: frm => frm.Name,
                        formKey: "formName"
                    },
                    {
                        title: 'Sort by Description',
                        header: 'Description',
                        field: frm => frm.Description,
                        formKey: "formDescription"
                    },
                    
                    {
                        title: 'Sort By Patient Type',
                        header: 'Form Type',
                        field: frm => frm.PatientType,

                    },
                    {
                        title: 'Sort By Patients count',
                        header: 'Assigned To Patients',
                        field: frm => frm.PatientsAssignedToForm,
                        numerable: true
                    },
                    {
                        title: 'Sort by Order',
                        header: 'Order',
                        field: frm => {
                            const order = frm.OnDemand ? "onDemand" : frm.Order
                            return order
                        },
                    },
                    {
                        title: 'Sort By Company',
                        header: 'Company',
                        field: frm => frm.Company,
                        // filterable: false
                    },
                    {
                        title: 'Sort by Active',
                        header: 'Active',
                        field: frm => frm.IsActive,
                        format: ({ FormId, IsActive, CompanyManagesPatientType, OnDemand }) => 
                            <FormTableCheckbox 
                                FormId={FormId} 
                                IsActive={IsActive} 
                                updateRowArray={updateRowArray} 
                                CompanyManagesPatientType={CompanyManagesPatientType} 
                                setFormToBeAssignedId={setFormToBeAssignedId}
                                otherFormIsLoading = {otherFormIsLoading}
                                setOtherFormIsLoading = {setOtherFormIsLoading}
                                isFormOnDemand={OnDemand}
                            />,
                        boolean: true
                    },
                    {
                        title: 'View Form',
                        header: 'View Form',
                        field: ({ FormId }) => <IoEye style={{ color: "#007bff", fontSize: "1.5em", cursor: 'pointer' }} onClick={() => handleView(FormId)} />,
                    },
                    {
                        title: 'Edit Form',
                        header: 'Edit Form',
                        field: frm => frm.CompanyManagesPatientType,
                        format: ({ FormId, CompanyManagesPatientType }) => CompanyManagesPatientType && <Link to={`/companies/${idCompany}/forms/editForm/${FormId}`}><IoEdit style={{ color: "#28a745", fontSize: "1.5em", cursor: 'pointer' }} /></Link>,
                        formKey: "editForm",
                        boolean: true

                    },
                    {
                        title: 'Copy Form',
                        header: 'Copy Form',
                        field: ({ FormId, Name, Order, PatientTypeId, OnDemand }) => <CopyForm formId={FormId} companyId={idCompany} name={Name} order={Order} patientStage={PatientTypeId} iconSize='1.5em' successAction={ ()=> !OnDemand && assignFormConfirmation() } setFormToBeAssignedId={(formId) => setFormToBeAssignedId(formId) } />,
                        formKey: "copyForm",
                    },
                    {
                        title: 'Assign Form',
                        header: 'Assign Form',
                        field: frm => frm.CanAssignPatients,
                        format: ({ FormId, CanAssignPatients }) => CanAssignPatients && <AssignFormToPatients openModal={ ()=> assignFormConfirmation(FormId) } />,
                        formKey: "assignForm",
                        boolean: true

                    },
                    
                ]}
                rows={formList}
                onExport={handleExport}
            />
        </div>
    )
}
DataColumnFilterTable.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.object),
    onExport: PropTypes.func,
    rows: PropTypes.arrayOf(PropTypes.object)
  };

Table.propTypes = {
    formList: PropTypes.arrayOf(PropTypes.object).isRequired,
    handleView: PropTypes.func.isRequired,
    idCompany: PropTypes.string.isRequired,
    updateRowArray: PropTypes.func.isRequired,
    assignFormConfirmation: PropTypes.func.isRequired,
    setFormToBeAssignedId: PropTypes.func.isRequired,
};
  