import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { InlineWaiting } from "components/ui";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form } from "formik";
import FormikField from "components/forms/formik-field";
import { isValidPhone, isValidEmail } from "utils/redux-form";
import { browserHistory } from "browser-history";
import PureModal from "components/ui/modal/pure-modal";
import PatientPhysicianForm from "components/physician-search/patient-physician-form";
import { aLink } from "utils/styles";
import { selectors, getFormOptionsTherapist } from "reducers";
import MdSearch from "react-icons/lib/md/search";
import PatientActiveCheckbox from "./patient-active-checkbox";
import HasRolePermssion from "components/shared/has-role-permission";
import { asyncValidateUniqueEmail, isValidUSZip } from "utils/form-validation";
import { STATES } from "utils/enum";
import initialValues from "./initial-values";
import CompanyBranchOptions from "./company-branch-options";
import PhoneNumberField from "components/forms/phone-number-field";
import * as Yup from "yup";
import PatientInsurancesForm from "./patient-insurances-form";
import useFormikAsyncValidationCache from "hooks/useFormikAsyncValidationCache";
import { useParams } from "react-router-dom";
import { useFetch } from "hooks/index.jsx";
import { get } from "utils/api.js";
import { path } from "ramda";
import { getTimezoneFromZip } from "utils/dates";
import { errorMessage } from "actions/message";
import { getApiErrorMessage } from "utils/misc";
import ConfirmationModal from "components/shared/confirmation-modal";

const PatientForm = ({ patient, handleSubmit }) => {
  const validateUniqueEmail = useFormikAsyncValidationCache(async email => {
    return !(await asyncValidateUniqueEmail(email, patient.id));
  });
  const validationSchema = Yup.lazy(() =>
    Yup.object().shape({
      first_name: Yup.string().required("Required"),
      last_name: Yup.string().required("Required"),
      account_number: Yup.string().required("Required"),
      email: Yup.string()
        .test(
          "checkValidEmail",
          "Please enter a valid email address.",
          email => !isValidEmail(email)
        )
        .test("checkDuplEmail", "Email is already used", validateUniqueEmail),
      date_of_birth: Yup.date()
        .max(new Date(), "The Date of Birth must be before today")
        .required("Required"),
      setup_date: Yup.date()
        .max(new Date(), "The Set up Date must be before today")
        .when("patient_type_id", {
          is: status =>
            status == "2" ||
            (patientTypes &&
              patientTypes.length == 1 &&
              patientTypes[0].ItemId == 2),
          then: () =>
            Yup.date()
              .required("Set Up Date is required for Compliance Patients")
              .typeError("Set Up Date is required for Compliance Patients"),
          otherwise: () => Yup.date().notRequired()
        }),
      primary_insurance: Yup.object().shape({
        insurance_company_id: Yup.string().required("Required"),
        insurance_company_name: Yup.string(),
        insurance_type_id: Yup.string(),
        insurance_type_name: Yup.string(),
        policy_end_date: Yup.date(),
        policy_start_date: Yup.date(),
        policy_number: Yup.string(),
        group_number: Yup.string(),
        guarantor_relation: Yup.string(),
        primary_order: Yup.number()
      }),
      phone_number: Yup.string()
        // .required("Required")
        .test(
          "valid-number",
          "Invalid phone number",
          val => !isValidPhone(val)
        ),
      address: Yup.object().shape({
        street_address: Yup.string().required("Required"),
        street_address2: Yup.string(),
        state: Yup.string().required("Please enter state"),
        zip: Yup.string()
          .required("Please enter zip code")
          .test("valid-zip", "Please enter a valid zip code", isValidUSZip),
        city: Yup.string().required("Please enter city")
      }),
      physician: Yup.object().shape({
        npi: Yup.string().matches(
          /^[0-9]{10}?$/,
          "NPI number must be of 10 digits"
        ),
        address: Yup.object().shape({
          zip: Yup.string().test(
            "valid-zip",
            "Please enter a valid zip code",
            val => !val || isValidUSZip(val)
          )
        })
      }),
      mobile_number: Yup.string().test(
        "valid-number",
        "Invalid phone number",
        val => !isValidPhone(val)
      ),
      form_of_contact: Yup.string().when("can_email", {
        is: value => value === false,
        then: () =>
          Yup.string().test(
            "can_email_form_of_contact",
            "If Allows resupply email is unchecked, Email cannot be a form of contact.",
            val => val !== "Email"
          ),
        otherwise: () => Yup.string().required("Required")
      }),
      patient_type_id: Yup.string().when("company_id", {
        is: () => !patientTypes || patientTypes.length > 1,
        then: () => Yup.string().required("Required"),
        otherwise: () => Yup.string().notRequired()
      })
    })
  );
  const formRef = useRef();
  const dispatch = useDispatch();
  const companies = useSelector(selectors.getFormOptionsCompany);
  const sleepCoaches = useSelector(
    selectors.getFormOptionsSleepCoachWithUserFirst
  );
  const therapists = useSelector(getFormOptionsTherapist);
  const formOfContactOptions = useSelector(selectors.getFormOfContactOptions);
  const genderOptions = useSelector(selectors.getGenderOptions);
  const { id } = useParams();
  const [patientTypes, setPatientTypes] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState();
  const [previousSelectedCompany, setPreviousSelectedCompany] = useState("");
  const [companyChangedByUser, setCompanyChangedByUser] = useState(false);
  const [companyManagesAnyPatientStage, setCompanyManagesAnyPatientStage] =
    useState(true);

  const complianceCoachesApi = useFetch({
    apiFn: () => get(`companies/compliance-coaches/${selectedCompany}`),
    defaultValue: []
  });

  const {
    fetchData: fetchPatientTypes,
    isFetching: fetchingPatientTypes,
    response: patientTypesResponseFetch
  } = useFetch({
    apiFn: () => get(`forms/patientTypes/${selectedCompany}`),
    defaultValue: {},
    transformError: path(["response", "body", "status"]),
    onError: error => {
      dispatch(
        errorMessage(
          `Failed to get patient stages for the selected company: ${getApiErrorMessage(
            error
          )}`
        )
      );
    }
  });

  const getPatientTypes = async () => {
    const patientTypesResponse = await fetchPatientTypes();
    setCompanyManagesAnyPatientStage(true);

    if (
      patientTypesResponse &&
      patientTypesResponse.Values &&
      patientTypesResponse.Values.length > 0 &&
      formRef.current &&
      formRef.current.values.patient_type_id != "0"
    ) {
      //i dont use patient.stage_id because the idea is to match the stage that is selected in Patient Stage
      if (
        patientTypesResponse.Values.some(
          element =>
            element.ItemId == Number(formRef.current.values.patient_type_id)
        )
      ) {
        setPatientTypes(patientTypesResponse.Values);
        setPreviousSelectedCompany(formRef.current.values.company_id);
        if (companyChangedByUser) {
          formRef.current.setFieldValue("compliance_coach_id", "");
          formRef.current.setFieldValue(
            "primary_insurance.insurance_company_id",
            ""
          );
          formRef.current.setFieldValue(
            "secondary_insurance.insurance_company_id",
            ""
          );
        }
      } else {
        document.getElementById("companyChangeConfirmation").click();
      }
    } else if (
      patientTypesResponse &&
      patientTypesResponse.Values &&
      patientTypesResponse.Values.length > 0 &&
      formRef.current &&
      formRef.current.values.patient_type_id == "0"
    ) {
      setPatientTypes(patientTypesResponse.Values);
      setPreviousSelectedCompany(formRef.current.values.company_id);
      if (companyChangedByUser) {
        formRef.current.setFieldValue("compliance_coach_id", "");
        formRef.current.setFieldValue(
          "primary_insurance.insurance_company_id",
          ""
        );
        formRef.current.setFieldValue(
          "secondary_insurance.insurance_company_id",
          ""
        );
      }
      if (
        patientTypesResponse.Values &&
        patientTypesResponse.Values.length == 1
      ) {
        formRef.current.setFieldValue(
          "patient_type_id",
          patientTypesResponse.Values[0].ItemId
        );
      } else {
        formRef.current.setFieldValue("patient_type_id", "1");
      }
    } else if (selectedCompany) {
      setCompanyManagesAnyPatientStage(false);
      document.getElementById("companyChangeConfirmation").click();
      setPatientTypes([]);
    } else {
      setPatientTypes([]);
    }
  };

  const rejectCompanychange = () => {
    setCompanyChangedByUser(false);
    formRef.current.values.company_id = previousSelectedCompany;
    setSelectedCompany(previousSelectedCompany);
  };

  const confirmCompanychange = () => {
    setPatientTypes(patientTypesResponseFetch.Values);
    setPreviousSelectedCompany(formRef.current.values.company_id);
    if (companyChangedByUser) {
      formRef.current.setFieldValue("compliance_coach_id", "");
      formRef.current.setFieldValue(
        "primary_insurance.insurance_company_id",
        ""
      );
      formRef.current.setFieldValue(
        "secondary_insurance.insurance_company_id",
        ""
      );
    }
    if (
      patientTypesResponseFetch.Values &&
      patientTypesResponseFetch.Values.length == 1
    ) {
      formRef.current.setFieldValue(
        "patient_type_id",
        patientTypesResponseFetch.Values[0].ItemId
      );
    }
  };

  useEffect(() => {
    if (selectedCompany) complianceCoachesApi.fetchData();

    getPatientTypes();
  }, [selectedCompany]);

  useEffect(() => {
    if (patient?.company_id) setSelectedCompany(patient.company_id);
  }, [patient]);

  const chosenCompanyName = () => {
    let companyFound = companies.find(
      element => element.value == selectedCompany
    );
    if (companyFound) {
      return companyFound.text;
    } else {
      return "Company name not available";
    }
  };

  const currentStageName = () => {
    if (formRef.current.values.patient_type_id == "1") {
      return "Resupply";
    } else if (formRef.current.values.patient_type_id == "2") {
      return "Compliance";
    } else if (formRef.current.values.patient_type_id == "3") {
      return "Remote Set Up";
    } else {
      return "";
    }
  };

  const futureStageName = () => {
    if (patientTypesResponseFetch.Values) {
      return patientTypesResponseFetch.Values[0].ItemValue;
    } else {
      return "";
    }
  };

  return (
    <Formik
      initialValues={initialValues(patient)}
      validationSchema={validationSchema}
      onSubmit={async values => {
        values.address.timezone = getTimezoneFromZip(values.address.zip);

        if (patientTypes && patientTypes.length == 1)
          values.patient_type_id = patientTypes[0].ItemId;

        if (id) {
          await handleSubmit(values);
        } else {
          await handleSubmit(values);
        }
      }}
      enableReinitialize
      innerRef={formRef}
    >
      {({ values, setFieldValue, isSubmitting }) => (
        <Form className="patient-form" autoComplete="off">
          <div>
            <PureModal
              renderTrigger={({ openModal }) => (
                <div
                  style={{ display: "none" }}
                  onClick={() => openModal()}
                  id="companyChangeConfirmation"
                ></div>
              )}
              renderContent={({ closeModal }) => (
                <ConfirmationModal
                  title={
                    companyManagesAnyPatientStage
                      ? `Attention: Company "${chosenCompanyName()}" does not manage ${currentStageName()}. By choosing this option, stage will be changed to ${futureStageName()}`
                      : `Attention: Company "${chosenCompanyName()}" does not manage Compliance or Resupply. Please choose another company`
                  }
                  closeModal={closeModal}
                  yesAction={confirmCompanychange}
                  notNowAction={rejectCompanychange}
                  yesOptionText={
                    companyManagesAnyPatientStage ? "Confirm" : "OK"
                  }
                  noOptionText="Cancel"
                  onlyShowOneButton={!companyManagesAnyPatientStage}
                />
              )}
              windowProps={{ closeable: false }}
            ></PureModal>
            <input type="hidden" value="disable-autofill" />
            <fieldset>
              <legend>Patient Profile</legend>
              <FormikField name="first_name" label="First Name *" type="text" />
              <FormikField name="last_name" label="Last Name *" type="text" />
              <FormikField
                name="account_number"
                label="Account Number *"
                type="text"
              />
              <FormikField
                label="Date of Birth *"
                name="date_of_birth"
                type="date"
              />
              <FormikField label="Set Up Date" name="setup_date" type="date" />
              <FormikField label="Gender" name="gender" component="select">
                <option />
                {genderOptions.map(({ text, value, key }) => (
                  <option key={key} value={value}>
                    {text}
                  </option>
                ))}
              </FormikField>
              <FormikField label="Email address" name="email" type="text" />
              <FormikField
                label="Phone Number *"
                name="phone_number"
                type="tel"
                component={PhoneNumberField}
              />
              <FormikField
                label="Mobile Number"
                name="mobile_number"
                type="tel"
                component={PhoneNumberField}
              />
              <FormikField
                label="Form of Contact *"
                name="form_of_contact"
                component="select"
              >
                <option value="" />
                {formOfContactOptions
                  .filter(({ active }) => active)
                  .map(({ text, value, key }) => (
                    <option value={value} key={key}>
                      {text}
                    </option>
                  ))}
              </FormikField>

              {values.form_of_contact == "Technology Only" && (
                <div className="outreach-method-statuses-container">
                  <div>What types of technology would the patient like?</div>
                  <div>
                    <FormikField
                      label="Text Messages:"
                      name="accepts_text"
                      type="checkbox"
                      checked={values.accepts_text}
                    />
                    <FormikField
                      label="Push Notifications:"
                      name="accepts_push_notifications"
                      type="checkbox"
                      checked={values.accepts_push_notifications}
                    />
                    <FormikField
                      label="Emails"
                      name="accepts_email"
                      type="checkbox"
                      checked={values.accepts_email}
                    />
                  </div>
                </div>
              )}

              <div className="patient-form-input patient-form-checkboxes">
                <FormikField
                  label="Allows resupply Email"
                  name="can_email"
                  type="checkbox"
                  checked={values.can_email}
                />
                <PatientActiveCheckbox
                  updatePatientForm={setFieldValue}
                  companyId={values.company_id}
                  value={values.active}
                />
                <FormikField
                  label="On Hold"
                  name="on_hold"
                  type="checkbox"
                  checked={values.on_hold}
                />
                <FormikField
                  label="Collections History"
                  name="collections_history"
                  type="checkbox"
                  checked={values.collections_history}
                />
                <FormikField
                  label="Spanish Speaker"
                  name="speaks_spanish"
                  type="checkbox"
                  checked={values.speaks_spanish}
                />
                <FormikField
                  label="Remote Setup"
                  name="remote_setup"
                  type="checkbox"
                  checked={values.remote_setup}
                />
              </div>
              <FormikField
                label="New Patient Setup?"
                name="new_setup"
                component="select"
              >
                <option value="true">
                  Yes, this is an initial machine and supply setup.
                </option>
                <option value="false">
                  No, the patient is already on PAP therapy and is only
                  receiving supplies.
                </option>
              </FormikField>
            </fieldset>
            <fieldset>
              <legend>Address</legend>
              <FormikField
                label="Street Address *"
                name="address.street_address"
                type="text"
              />
              <FormikField
                label="Street Address Line 2"
                name="address.street_address2"
                type="text"
              />
              <div
                className="address-fields"
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr",
                  gridGap: 10
                }}
              >
                <FormikField label="City *" name="address.city" type="text" />
                <FormikField
                  label="State *"
                  name="address.state"
                  component="select"
                >
                  <option value="" />
                  {STATES.map(({ value, text }) => (
                    <option key={value} value={value}>
                      {text}
                    </option>
                  ))}
                </FormikField>
                <FormikField label="ZIP *" name="address.zip" type="text" />
              </div>
            </fieldset>
            <fieldset>
              <legend>Password</legend>
              <FormikField
                label="Password"
                name="password.password"
                type="password"
              />
              <FormikField
                label="Password Confirmation"
                name="password.password_confirmation"
                type="password"
              />
            </fieldset>
          </div>
          <div>
            <fieldset>
              <legend>Additional Info</legend>
              <FormikField
                label="Company"
                name="company_id"
                component="select"
                onChange={({ target }) => {
                  var companyObject = companies.find(
                    x => x.value === target.value
                  );
                  var defaultBranch =
                    companyObject?.branches?.length === 1
                      ? companyObject?.branches[0]?.value
                      : "";
                  setFieldValue("company_id", target.value);
                  setFieldValue("branch_office_id", defaultBranch);
                  setFieldValue("therapist_id", "");
                  setCompanyChangedByUser(true);
                  setSelectedCompany(target.value);
                }}
                disabled={fetchingPatientTypes}
              >
                <option />
                {companies.map(({ text, value }) => (
                  <option key={value} value={value}>
                    {text}
                  </option>
                ))}
              </FormikField>
              <FormikField
                label="Branch Office"
                name="branch_office_id"
                component="select"
              >
                <CompanyBranchOptions
                  companyId={values.company_id}
                  companies={companies}
                />
              </FormikField>
              <HasRolePermssion allowedRoles={["Administrator", "SleepCoach"]}>
                <FormikField
                  label="Therapist"
                  name="therapist_id"
                  component="select"
                >
                  <option />
                  {therapists
                    .filter(({ company }) => company == values.company_id)
                    .map(({ text, value }) => (
                      <option key={value} value={value}>
                        {text}
                      </option>
                    ))}
                </FormikField>
              </HasRolePermssion>
              <HasRolePermssion
                allowedRoles={[
                  "Administrator",
                  "SleepCoach",
                  "ServiceAdmin",
                  "ContractorAdministrator"
                ]}
              >
                <FormikField
                  label="Sleep Coach"
                  name="sleep_coach_id"
                  component="select"
                >
                  <option value="" />
                  {sleepCoaches.map(({ text, value }) => (
                    <option key={value} value={value}>
                      {text}
                    </option>
                  ))}
                </FormikField>
              </HasRolePermssion>
              {complianceCoachesApi.isFetching ? (
                <InlineWaiting />
              ) : (
                <FormikField
                  label="Compliance Coach"
                  name="compliance_coach_id"
                  component="select"
                >
                  <option value="" />
                  {complianceCoachesApi.response?.map(({ text, value }) => (
                    <option key={value} value={value}>
                      {text}
                    </option>
                  ))}
                </FormikField>
              )}
            </fieldset>
            <fieldset>
              <legend>Patient Stage</legend>
              {fetchingPatientTypes ? (
                <InlineWaiting />
              ) : (
                <FormikField
                  label="Initial Stage"
                  name="patient_type_id"
                  component="select"
                >
                  {patientTypes &&
                    patientTypes.map(({ ItemId, ItemValue }) => (
                      <option key={ItemId} value={ItemId}>
                        {ItemValue}
                      </option>
                    ))}
                </FormikField>
              )}
            </fieldset>
            <PatientInsurancesForm />
            <fieldset>
              <legend>Physician</legend>
              <span style={{ display: "flex", justifyContent: "flex-end" }}>
                <PureModal
                  renderTrigger={({ openModal }) => (
                    <span onClick={openModal} style={aLink}>
                      <MdSearch /> Lookup Physician
                    </span>
                  )}
                  renderContent={({ closeModal }) => (
                    <PatientPhysicianForm
                      physician={values.physician}
                      updatePatientPhysician={values => {
                        setFieldValue("physician", values);
                        closeModal();
                      }}
                    />
                  )}
                />
              </span>
              <FormikField
                label="NPI Number"
                name="physician.npi"
                type="number"
              />
              <FormikField label="Name" name="physician.name" type="text" />
              <FormikField
                label="Street"
                name="physician.address.street_address"
                type="text"
              />
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr",
                  gridGap: 10
                }}
              >
                <FormikField
                  label="City"
                  name="physician.address.city"
                  type="text"
                />
                <FormikField
                  label="State"
                  name="physician.address.state"
                  component="select"
                >
                  <option value="" />
                  {STATES.map(({ value, text }) => (
                    <option key={value} value={value}>
                      {text}
                    </option>
                  ))}
                </FormikField>
                <FormikField
                  label="Zip"
                  name="physician.address.zip"
                  type="text"
                />
              </div>

              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gridGap: 10
                }}
              >
                <FormikField
                  label="Phone"
                  name="physician.phone"
                  type="tel"
                  component={PhoneNumberField}
                />
                <FormikField
                  label="Fax"
                  name="physician.fax"
                  type="tel"
                  component={PhoneNumberField}
                />
              </div>
              <span style={{ visibility: "hidden" }}>
                <FormikField label="" name="physician.id" type="text" />
              </span>
            </fieldset>
            {isSubmitting ? (
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  justifyContent: "flex-end",
                  padding: "15px 5px"
                }}
              >
                <InlineWaiting />
              </div>
            ) : (
              <div className="form-buttons">
                <button
                  className="alert button"
                  onClick={browserHistory.goBack}
                  disabled={isSubmitting}
                  type="button"
                >
                  Cancel
                </button>
                <button type="submit">Submit</button>
              </div>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

PatientForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submission: PropTypes.shape({
    isSubmitting: PropTypes.bool,
    patientId: PropTypes.string
  }),
  patient: PropTypes.object,
  title: PropTypes.string,
  physician: PropTypes.object
};

PatientForm.defaultProps = {
  patient: {
    first_name: "",
    last_name: "",
    account_number: "",
    date_of_birth: "",
    setup_date: "",
    gender: "",
    email: "",
    phone_number: "",
    mobile_number: "",
    special_instructions: "",
    form_of_contact: "",
    can_email: true,
    speaks_spanish: false,
    active: true,
    on_hold: false,
    new_setup: true,
    remote_setup: false,
    // insurance_company_id: "",
    collections_history: false,
    accepts_email: true,
    accepts_text: true,
    accepts_push_notifications: true,
    address: {
      street_address: "",
      street_address2: "",
      city: "",
      state: "",
      zip: ""
    },
    company_id: "",
    branch_office_id: "",
    therapist_id: "",
    sleep_coach_id: "",
    physician: {
      name: "",
      phone: "",
      npi: "",
      fax: "",
      address: {
        street_address: "",
        city: "",
        state: "",
        zip: ""
      }
    },
    compliance_coach_id: ""
  }
};

export default PatientForm;
